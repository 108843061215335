import { all } from "async-saga";
import { services } from "../services";
import { actions } from "../types";
import { takeEvery } from "./async-saga";

// const fetchCurrentResourceBooking = takeEvery(actions.fetchCurrentResourceBooking.request, async function(ctx, resourceEmail) {
//     try {
//         if (!services.bookItClient) {
//             console.error("No BookItClient defined")
//             return
//         }

//         if (!resourceEmail) {
//             console.error("No resourceEmail defined")
//         }

//         const res = await services.bookItClient.getCurrentResourceBooking(resourceEmail)
//         ctx.dispatch(actions.fetchCurrentResourceBooking.success(res))
//     } catch (e) {
//         ctx.dispatch(actions.fetchCurrentResourceBooking.failure(e))
//     }
// })

const fetchQuickBookStatus = takeEvery(actions.fetchQuickBookStatus.request, async function (ctx, {roomEmail, tagType, durMin}) {
    try {
        if (!services.bookItClient) {
            console.error("No BookItClient defined")
            return
        }
        const res = await services.bookItClient.getQuickBookStatus(roomEmail, tagType, durMin)
        ctx.dispatch(actions.fetchQuickBookStatus.success(res))
    } catch (e) {
        ctx.dispatch(actions.fetchQuickBookStatus.failure(e))
    }
})

const bookQuickBook = takeEvery(actions.bookQuickBook.request, async function (ctx, { room, start, durMin, resourceType, startNow }) {
    try {

        if (!services.bookItClient) {
            console.error("No BookItClient defined")
            return
        }
        
        console.log('bookQuickBook', {room: room, start: start, durMin: durMin, resourceType: resourceType, startNow})

        if (resourceType === 'MeetingRoom') {
            const meeting = await services.bookItClient.bookRoom(room, start, durMin, startNow)
            ctx.dispatch(actions.bookQuickBook.success(meeting))
        } else {
            const meeting = await services.bookItClient.bookResource(room, start, durMin, startNow)
            ctx.dispatch(actions.bookQuickBook.success(meeting))
        }
        

    } catch (e) {
        ctx.dispatch(actions.bookQuickBook.failure(e))
    }
})

const bookQuickBookTagScanned = takeEvery(actions.fetchQuickBookTagScanned.request, async function (ctx, { roomEmail, tagType, resourceType }) {
    try {

        if (!services.bookItClient) {
            console.error("No BookItClient defined")
            return
        }
        
        console.log('bookQuickBookTagScanned', {roomEmail: roomEmail, tagType: tagType})

        const response = await services.bookItClient.quickBookTagScanned(roomEmail, resourceType)
        ctx.dispatch(actions.fetchQuickBookTagScanned.success(response))
       

    } catch (e) {
        ctx.dispatch(actions.fetchQuickBookTagScanned.failure(e))
    }
})

export default all(
    fetchQuickBookStatus,
    //fetchCurrentResourceBooking,
    bookQuickBookTagScanned,
    bookQuickBook,
)