import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { CalendarContext, MeetingData } from '../../models';
import { Actions, actions } from '../types';
import { ApiResult } from './ApiResult';
import { createApiReducer } from './createApiReducer';

function apiCallForMeeting(state: Record<string, boolean> = {}, action: Actions): Record<string, boolean> {
    switch (action.type) {
        case getType(actions.startApiCallForMeeting):
            console.log("start api call reducer:", action.payload)
            return { ...state, [action.payload]: true }
        case getType(actions.finishApiCallForMeeting):
            console.log("finish api call reducer:", action.payload)
            const { [action.payload]: _, ...newState } = state
            return newState

    }
    return state
}

interface UpcomingMeetingsState {
    context: CalendarContext
    data: ApiResult<Array<MeetingData>>
    backgroundRefresh: boolean
}

function upcomingMeetings(state: UpcomingMeetingsState | null = null, action: Actions): UpcomingMeetingsState | null {
    switch (action.type) {
        case getType(actions.refreshUpcomingMeetings):
            if (state === null) return null
            return {...state, backgroundRefresh: true}
        case getType(actions.fetchUpcomingMeetings.request):
            return { context: {...action.payload, email: action.payload.email.toLowerCase()},
                     data: ApiResult.loading(ApiResult.default()),
                     backgroundRefresh: state?.backgroundRefresh ?? false
                    }
        case getType(actions.fetchUpcomingMeetings.success):
            // only process if it was the last request
            if (action.payload.context.email.toLowerCase() === state!.context.email) {
                return { ...state!, data: ApiResult.success(action.payload.data), backgroundRefresh: false }
            }
            break;
        case getType(actions.fetchUpcomingMeetings.failure):
            return { ...state!, data: ApiResult.failure(action.payload), backgroundRefresh: false }
    }

    return state
}

export default combineReducers({
    upcomingMeetings,
    upcomingMeetingsForResource: createApiReducer(actions.fetchUpcomingMeetingsForResource),
    cancelMeeting: createApiReducer(actions.cancelMeeting),
    extendMeeting: createApiReducer(actions.extendMeeting),
    leaveMeeting: createApiReducer(actions.leaveBookItMeeting),
    startMeeting: createApiReducer(actions.startMeeting),
    startVfxMeeting: createApiReducer(actions.startVfxMeeting),
    leaveVfxMeeting: createApiReducer(actions.leaveMeeting),
    apiCalls: apiCallForMeeting
})
