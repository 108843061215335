
import classNames from 'classnames'
import React from "react"
import { ReactComponent as CarIcon } from '../../icons/car.svg'
import { ReactComponent as CarParkIcon } from '../../icons/carpark.svg'
import { ReactComponent as HotDeskIcon } from '../../icons/hotdesk.svg'
import { ReactComponent as LockerIcon } from '../../icons/locker.svg'
import { ReactComponent as MeetingRoomIcon } from '../../icons/meetingroom.svg'
import { ResourceType } from '../../models'
import css from './ResourcePersona.module.scss'


interface ResourcePersonaProps {
    resourceType: ResourceType
    className: string
}

export function ResourcePersona({resourceType, className}: ResourcePersonaProps): JSX.Element {

    return <div className={classNames(css.resourcePersona, className)}>
        {resourceType === "HotDesk" && <HotDeskIcon className={css.resourcePersonaIcon} />}
        {resourceType === "ParkingSpace" && <CarParkIcon className={css.resourcePersonaIcon} />}
        {resourceType === "Locker" && <LockerIcon className={css.resourcePersonaIcon} />}
        {resourceType === "Vehicle" && <CarIcon className={css.resourcePersonaIcon} />}
        {resourceType === "MeetingRoom" && <MeetingRoomIcon className={css.resourcePersonaIcon} />}
    </div>
}


