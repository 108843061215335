import { UserManager } from 'oidc-client';
import { AuthenticationProvider } from '../api/AuthenticationProvider';
import { AuthenticationProviderOptions } from '../api/AuthenticationProviderOptions';

export class OidcClientAuthenticationProvider implements AuthenticationProvider {
    private userManager: UserManager;
    constructor(userManager: UserManager) {
        this.userManager = userManager;
    }
    async getAccessToken(authenticationProviderOptions?: AuthenticationProviderOptions): Promise<string> {
        
        const user = await this.userManager.getUser();
        if (user === null) {
            return Promise.reject(new Error("User not authed"));
        }
        return user.access_token;    
    }
}
