import { connectRouter } from "connected-react-router";
import { History } from 'history';
import { combineReducers } from "redux";
import { reducer as oidcReducer } from 'redux-oidc';
import auth from "./auth";
import error from "./error";
import hosted from "./hosted";
import meetings from "./meetings";
import nearby from "./nearby";
import quickBook from "./quickBook";
import videofx from "./videofx";




export function createRootReducer<T>(history: History<T>) {
    return combineReducers({
        router: connectRouter<T>(history),
        oidc: oidcReducer,
        auth,
        meetings,
        videofx,
        hosted,
        nearby,
        quickBook,
        error
    })
}