import { push } from "connected-react-router";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { User } from "oidc-client";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../auth/userManager";
import "./App.css";

const OidcCallback: React.FC<{}> = () => {
  const dispatch = useDispatch()

  const onSuccess = useCallback((user: User) => {
    const redirectPath = user.state === undefined ? "/" : user.state.path as string;
    console.log("OIDC callback error success: ", user)
    console.log("redirectPath: ", redirectPath)
    dispatch(push(redirectPath));
  }, [dispatch])


  const onError = useCallback((error: any) => {
    console.error("OIDC callback error", error)
    dispatch(push("/"));
    // tslint:disable-next-line:no-console
    console.error(error);
  }, [dispatch])


  console.log('OIDC Callback')
  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={onSuccess}
      errorCallback={onError}
    >
      <Spinner label="Signing in..." size={SpinnerSize.large} ariaLive="assertive" labelPosition="bottom" className='login-spinner' />
    </CallbackComponent>
  );
}


export default OidcCallback