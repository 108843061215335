import { AppType, UserAccount } from "../models"
import { urlJoin } from "../utils/urlJoin"

declare global {
    interface Window {
        ApplicationHosting?: ApplicationHostingInterface
    }
}

interface ApplicationHostingContext {
    emailAddress: string
    givenName: string
    familyName: string
    roles: string[]
    accessToken: string
    videoFxApiBaseUrl: string
    bookItApiBaseUrl: string
    launchAction?: LaunchAction

    version?: number
    platform?: string
}

type FABActions = "LocationCode" | "StartBooking"

interface ApplicationHostingInterface {
    context(): string
    sendAction(action: 'Navigate' | 'WebAppAction', appType: AppType, args: string[]): void
    setTitle(title: string): void
    showBack(show: boolean): void
    setFABAction(action: FABActions, context: Array<String> | undefined): void
    getAccessToken(completionId: number): Promise<string | undefined>
    appReady(): void
}

export type AppAction = {
    appType: AppType
    type: 'Navigate' | 'WebAppAction',
    args: string[]
}

export interface HostingClient {
    user: () => Promise<UserAccount>
    accessToken: () => Promise<string | undefined>
    videoFxApiBaseUrl: () => Promise<string>,
    bookItApiBaseUrl: () => Promise<string>
    platform: "Android" | "iOS" | "Browser",
    navigateTo(app: AppType, path: string): void
    launchAction(app: AppType, args: string[]): void
    getLaunchAction(): Promise<LaunchAction | null>
    setTitle(title: string): void
    showBack(back: boolean): void
    setFABAction(action: FABActions, context: Array<String> | undefined): void
    appReady(): void
}

export function createiOSHostingClient(): HostingClient | undefined {
    const applicationHosting = (window as any).webkit?.messageHandlers?.applicationHosting
    const accessToken = (window as any).webkit?.messageHandlers?.accessToken
    const sendAction = (window as any).webkit?.messageHandlers?.sendAction
    const setTitle = (window as any).webkit?.messageHandlers?.setTitle
    const showBack = (window as any).webkit?.messageHandlers?.showBack
    const setFABAction = (window as any).webkit?.messageHandlers?.setFABAction
    const appReady = (window as any).webkit?.messageHandlers?.appReady

    if (applicationHosting !== undefined && accessToken !== undefined && sendAction !== undefined) {
        const parseContext = async () => {
            const ctx = await applicationHosting.postMessage({})
            return JSON.parse(ctx) as ApplicationHostingContext
        }

        return {
            user: async (): Promise<UserAccount> => {
                const ctx = await parseContext()
                return {
                    givenName: ctx.givenName,
                    familyName: ctx.familyName,
                    emailAddress: ctx.emailAddress,
                    roles: ctx.roles
                }
            },
            videoFxApiBaseUrl: async () => (await parseContext()).videoFxApiBaseUrl,
            bookItApiBaseUrl: async () => (await parseContext()).bookItApiBaseUrl,
            accessToken: () => accessToken.postMessage({}),
            platform: "iOS",
            navigateTo: (app: AppType, path: string) => sendAction.postMessage(JSON.stringify({ $type: 'Navigate', app, args: [path] })),
            launchAction: (app: AppType, args: string[]) => sendAction.postMessage(JSON.stringify({ $type: 'WebAppAction', app, args })),
            async getLaunchAction() {
                const ctx = await parseContext()
                return ctx?.launchAction ?? null
            },
            setTitle: (title: string) => {
                setTitle.postMessage(title)
            },
            showBack: (back: boolean) => {
                showBack.postMessage(back)
            },
            setFABAction: (action: FABActions, context: Array<String> | undefined) => {
                setFABAction.postMessage(action)
            },
            appReady: () => {
                appReady?.postMessage({})
            }
        }
    }

    console.warn("Application is not hosted in iOS")
    return undefined
}

export function createAndroidHostingClient(): HostingClient | undefined {
    const hosting = window.ApplicationHosting

    if (hosting !== undefined) {
        let completionId = 1
        const pending = new Map<number, (accessToken: string | undefined) => void>();

        (window as any).accessTokenCompleted = (completionId: number, accessToken: string | undefined) => {
            const entry = pending.get(completionId)
            if (entry) {
                entry(accessToken)
            }
        }

        const getAccessToken = () => {
            const id = completionId++
            return new Promise<string | undefined>((resolve, reject) => {
                pending.set(id, (token: string | undefined) => {
                    const entry = pending.get(id)
                    if (entry) {
                        pending.delete(id)
                        resolve(token)
                    }
                })
                console.log("Called hosting getAccessToken()")
                hosting.getAccessToken(id)
                console.log("Waiting for completion callback from native code")
            })
        }

        const parseContext = () => {
            const ctx = hosting.context()
            return JSON.parse(ctx) as ApplicationHostingContext
        }

        return {
            user: async (): Promise<UserAccount> => {
                const ctx = parseContext()
                return {
                    givenName: ctx.givenName,
                    familyName: ctx.familyName,
                    emailAddress: ctx.emailAddress,
                    roles: ctx.roles
                }
            },
            videoFxApiBaseUrl: async () => parseContext().videoFxApiBaseUrl,
            bookItApiBaseUrl: async () => parseContext().bookItApiBaseUrl,
            accessToken: async () => await getAccessToken(),
            platform: "Android",
            navigateTo: (app: AppType, path: string) => hosting.sendAction('Navigate', app, [path]),
            launchAction: (app: AppType, args: string[]) => hosting.sendAction('WebAppAction', app, args),
            async getLaunchAction() {
                const ctx = parseContext()
                return ctx?.launchAction ?? null
            },
            setTitle: (title: string) => {
                hosting.setTitle(title)
            },
            showBack: (back: boolean) => {
                hosting.showBack(back)
            },
            setFABAction: (action: FABActions, context: Array<String> | undefined) => {
                hosting.setFABAction(action, context)
            },
            appReady: () => {
                if (hosting.appReady !== undefined) {
                    hosting.appReady()
                }
            }
        }
    }

    console.info("Application is not hosted Android")
    return undefined
}

export function createOidcHostingClient(emailAddress: string, familyName: string, givenName: string, videoFxApiRoot: string, bookItApiRoot: string, nav: (url: string) => void): HostingClient {
    return {
        user: async () => {
            return {
                givenName: givenName,
                familyName: familyName,
                emailAddress: emailAddress,
                roles: []
            }
        },
        videoFxApiBaseUrl: async () => videoFxApiRoot,
        bookItApiBaseUrl: async () => bookItApiRoot,
        accessToken: async () => undefined,
        platform: "Browser",
        navigateTo: (app: AppType, path: string) => {
            // just go to the url
            const url = urlJoin([bookItApiRoot, path])
            nav(url)
        },
        launchAction() {
            alert('not supported')
        },
        getLaunchAction: async () => null,
        setTitle: (title: string) => {
        },
        showBack: (back: boolean) => {
        },
        setFABAction: (action: FABActions, context: Array<String> | undefined) => {
        },
        appReady: () => {
        }
    }
}

export function listenLaunchAction(callback: (action: LaunchAction) => void) {
    window.addEventListener('message', onMessage)
    console.log('Added event listener message to window')
    return () => window.removeEventListener('message', onMessage)

    function onMessage(ev: MessageEvent) {
        console.log('Message received', ev)
        if (typeof ev.data !== 'string' || !ev.data.includes('$type')) { return }

        try {
            const encodedMessage = JSON.parse(ev.data) as EncodedLaunchMessage
            console.log('Message parsed', encodedMessage)
            const decodedArgs = encodedMessage.args.map(arg => {
                if (arg.startsWith("_$")) {
                    return arg.substring(2)
                        .replaceAll("_%", "\"")
                        .replaceAll("_@", "'")
                } else {
                    return arg
                }
            })
            console.log('Message args decoded', decodedArgs)
            encodedMessage.args = decodedArgs
            const message = encodedMessage as LaunchMessage
            console.log('Message decoded', message)

            if (message['$type'] !== 'IpfxAction') { return }

            callback(message.args);
        } catch (e) {
            console.log('Message bad', e)
        }
    }

}

interface EncodedLaunchMessage {
    $type: 'IpfxAction',
    args: string[]
}

interface LaunchMessage {
    $type: 'IpfxAction',
    args: LaunchAction
}

export type LaunchAction = [string, ...string[]]
