import { Context } from "vm";
import { Middleware } from "./Middleware";

export class HTTPMessageHandler implements Middleware {
	
	public async execute(context: Context): Promise<void> {
		try {
            context.response = await fetch(context.request, context.options);
            // no call to next as we are always the end of the pipeline
			return;
		} catch (error) {
			throw error;
		}
	}
}