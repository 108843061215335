import { all } from "async-saga";
import { push } from "connected-react-router";
import { services } from "../services";
import { actions } from "../types";
import { takeEvery } from "./async-saga";

const nearbyTap = takeEvery(actions.nearbyTap, async function (ctx, nearbyTap) {
    console.log("nearbyTapSaga:", nearbyTap)

    if (nearbyTap.state) { return }

    // don't fetch the meeting state if we have a no_primary_device tag (i.e. Room Schedule)
    if (services.bookItClient && !nearbyTap.no_primary_device) {
        const state = await services.bookItClient.quickBookTagScanned(nearbyTap.room, nearbyTap.default_resource!)

        if (state.startedState !== 'None') {
            nearbyTap = {
                ...nearbyTap,
                state,
            }
        }
        console.log("After quickBookTagScanned: nearbyTap=", nearbyTap)

    }

    // for now send resource taps without any active meeting straight to bookit
    if (!nearbyTap.state) {
        if (nearbyTap.default_resource && nearbyTap.default_resource !== "MeetingRoom") {
            const data = {
                email: nearbyTap.room.toLowerCase(),
                type: nearbyTap.default_resource ?? nearbyTap.type
            }

            const url = `#nearby=${encodeURIComponent(JSON.stringify(data))}`
            ctx.dispatch(actions.navigateAppTo("BookIt", url))

        } else {
            console.log("nearbyTapSaga::switchCalendarContext()")
            ctx.dispatch(actions.switchCalendarContext({email: nearbyTap.room, name: nearbyTap.name, resourceType: nearbyTap.default_resource!!,
                informationText: nearbyTap.resource_information_text, photoUrl: nearbyTap.resource_photo_url, floorPlanUrl: nearbyTap.resource_floor_plan_url}))
        }
    } else {

        ctx.dispatch(actions.nearbyTap(nearbyTap))
        ctx.dispatch(push('/'))
    }
})

export default all(
    nearbyTap,
)