import React, { useEffect } from "react";
import { processSilentRenew } from 'redux-oidc';

const OidcSilentRenew: React.FC<{}> = () => {
    console.info('Processing Silent Renew callback')
    useEffect(() => processSilentRenew())
    return null;
}


export default OidcSilentRenew