import { NeutralColors } from "@uifabric/fluent-theme"
import { ICardSectionStyles, ICardSectionTokens, ICardStyles } from "@uifabric/react-cards"
import { FontWeights, IIconStyles, ITextStyles } from "office-ui-fabric-react"

export const getCardStyles = (): ICardStyles => {
    return {
        root: {
            paddingTop: 16,
            paddingLeft: 16,
            paddingBottom: 16,
            paddingRight: 16,
            minWidth: 0,
            // minHeight: 130,
            maxWidth: 'auto',
            background: NeutralColors.white,
            // background: isStarted ? "#f0cccd" : NeutralColors.white,
            textAlign: 'center',
            marginTop: '8px'
        }
    }
}

export const buttonIconStyles: IIconStyles = {
    root: {
        height: '32px'
    }
}

export const tagTextStyle: ITextStyles = {
    root: {
        fontWeight: FontWeights.semilight,
        color: NeutralColors.gray120,
        marginLeft: 'auto',
        fontSize: '0.8rem'
    }
}

export const footerCardSectionStyles: ICardSectionStyles = {
    root: {
        borderTop: '1px solid #F3F2F1',
        paddingTop: 8
    }
}

export const footerCardSectionTokens: ICardSectionTokens = { padding: 0 }