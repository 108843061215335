import { all } from "async-saga";
import { push } from "connected-react-router";
import { StartVideoFxMeetingModel } from "../../models";
import { ApiResult } from "../reducers/ApiResult";
import { getVideoFxRoomClient } from "../selectors";
import { actions } from "../types";
import { SagaContext, takeEvery } from "./async-saga";

const videoFxRoomTap = takeEvery(actions.videoFxRoomTap, async function (ctx) {
    ctx.dispatch(push('/videofx-room-tap'))
})

const startDesktopApplication = takeEvery(actions.startDesktopApplication.request, async function (ctx, { organisationId, roomId, link }) {
    try {

        const roomKey = organisationId + '_' + roomId
        const client = getVideoFxRoomClient(ctx.getState(), roomKey);

        const user = ctx.getState().auth.user;
        if (!client || !ApiResult.isSuccess(user)) {
            console.error("No VideoFX Room Client or user found")
            return
        }
        await client.startDesktopApplication(link, { sign_in_address: user.value.emailAddress, password: null });
        ctx.dispatch(actions.startDesktopApplication.success(null))
    } catch (e) {
        ctx.dispatch(actions.startDesktopApplication.failure(e))
    }
})

const addTeamToRoom = takeEvery(actions.addTeamToRoom.request, async function (ctx, { organisationId, roomId, team }) {
    try {
        const roomKey = organisationId + '_' + roomId
        const client = getVideoFxRoomClient(ctx.getState(), roomKey);
        const user = ctx.getState().auth.user;
        if (!client || !ApiResult.isSuccess(user)) {
            console.error("No VideoFX Room Client or user found")
            return
        }
        await client.addTeamToRoom(team.link, team);
        ctx.dispatch(actions.startDesktopApplication.success(null))
    } catch (e) {
        ctx.dispatch(actions.startDesktopApplication.failure(e))
    }
})

const leaveMeeting = takeEvery(actions.leaveMeeting.request, async function (ctx, { organisationId, roomId, link }) {
    try {

        const roomKey = organisationId + '_' + roomId
        const client = getVideoFxRoomClient(ctx.getState(), roomKey);
        const user = ctx.getState().auth.user;

        if (!client || !ApiResult.isSuccess(user)) {
            console.error("No VideoFX Room Client or user found")
            return
        }
        await client.leaveMeeting(link);
        ctx.dispatch(actions.leaveMeeting.success(null))
    } catch (e) {
        ctx.dispatch(actions.leaveMeeting.failure(e))
    }
})

const startVfxMeeting = takeEvery(actions.startVfxMeeting.request, async function (ctx, { organisationId, roomId, link, body }) {
    try {
        const user = ctx.getState().auth.user;
        if (ApiResult.isSuccess(user)) {
            const roomKey = organisationId + '_' + roomId
            const client = getVideoFxRoomClient(ctx.getState(), roomKey);
            console.log(`startVfxMeeting roomKey: ${roomKey}`)

            if (!client) {
                console.error("No VideoFX Room Client found")
                return
            }
            await client.startVfxMeeting(link, body);
            ctx.dispatch(actions.startVfxMeeting.success(null))
        } else {
            console.error("No User found")
            return
        }
    } catch (e) {
        ctx.dispatch(actions.startVfxMeeting.failure(e))
    }
})

export default all(
    videoFxRoomTap,
    startDesktopApplication,
    addTeamToRoom,
    leaveMeeting,
    startVfxMeeting,
)