import { Context } from "./Context";
import { Middleware } from "./Middleware";
export class HTTPClient {
    private middleware: Middleware;
    public constructor(middleware: Middleware) {
        this.middleware = middleware;
    }
    public async sendRequest(context: Context): Promise<Context> {
        try {
            if (typeof context.request === "string" && context.options === undefined) {
                const error = new Error();
                error.name = "InvalidRequestOptions";
                error.message = "Unable to execute the middleware, Please provide valid options for a request";
                throw error;
            }
            await this.middleware.execute(context);
            return context;
        }
        catch (error) {
            throw error;
        }
    }
}
