import { all } from "async-saga";
import auth from "./auth";
import hosted from "./hosted";
import meetings from "./meetings";
import nearby from "./nearby";
import quickBook from "./quickBook";
import videofx from "./videofx";

export default all(
    auth,
    meetings,
    hosted,
    videofx,
    quickBook,
    nearby
)