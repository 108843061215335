import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { MeetingData, QuickBookBooking, QuickBookTap } from '../../models';
import { Actions, actions } from '../types';
import { createApiReducer, createApiReducerWithTransform } from './createApiReducer';

function quickBook(state: QuickBookTap | null = null, action: Actions): QuickBookTap | null {
    switch (action.type) {
        case getType(actions.quickBookTap): {
            return action.payload
        }
    }

    return state
}

function toQuickBook(meeting: MeetingData): QuickBookBooking {
    return {
        roomEmail: '',
        roomName: '',
        isBookable: false,
        start: meeting.startTime,
        end: meeting.endTime,
        isMine: true,
        id: meeting.id,
    }
}

export default combineReducers({
    quickBook,
    booking: createApiReducerWithTransform(actions.bookQuickBook, toQuickBook),
    tagScanned: createApiReducer(actions.fetchQuickBookTagScanned),
})