import { ApiResult } from "./reducers/ApiResult";
import { RootState } from "./types";

export function getVideoFxRoomClient(state: RootState, roomId: string | undefined) {
    return roomId ? state.auth.videoFxRoomClients[roomId] : undefined;
}

export function requireUserAccount(state: RootState) {
    if (ApiResult.isSuccess(state.auth.user)) {
        return state.auth.user.value
    }
    throw Error("Cannot be used without an authorised user")
}

