import { AnyAction, Reducer } from "redux";
import { ActionCreator, getType, PayloadActionCreator } from "typesafe-actions";
import { ApiResult } from "./ApiResult";

interface ApiAction<T> {
    request: ActionCreator
    success: PayloadActionCreator<string, T>
    failure: PayloadActionCreator<string, Error>
}

export function createApiReducer<T>(apiAction: ApiAction<T>) {
    return createApiReducerWithTransform(apiAction)
}


export function createApiReducerWithTransform<T>(apiAction: ApiAction<T>): Reducer<ApiResult<T>>
export function createApiReducerWithTransform<T, D>(apiAction: ApiAction<D>, transform: (data: D) => T): Reducer<ApiResult<T>>
export function createApiReducerWithTransform<T, D>(apiAction: ApiAction<D>, transform?: (data: D) => T) {
    return (state: ApiResult<T> = ApiResult.default(), action: AnyAction): ApiResult<T> => {
        switch (action.type) {
            case getType(apiAction.request):
                return ApiResult.loading(state)
            case getType(apiAction.success):
                return ApiResult.success(transform ? transform(action.payload) : action.payload)
            case getType(apiAction.failure):
                return ApiResult.failure(action.payload)
            default:
                return state;
        }
    }
}