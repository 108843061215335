import { AuthenticationProvider } from '../api/AuthenticationProvider'
import { AuthenticationProviderOptions } from '../api/AuthenticationProviderOptions'
import { HostingClient } from './applicationHostingClient'

export class ApplicationHostingAuthenticationProvider implements AuthenticationProvider {
    private hostingClient: HostingClient
    
    constructor(hostingClient: HostingClient) {
        this.hostingClient = hostingClient
    }
    async getAccessToken(authenticationProviderOptions?: AuthenticationProviderOptions): Promise<string> {
        return (await this.hostingClient!.accessToken())!
    }
}


