import { SharedColors } from '@uifabric/fluent-theme';
import { Card } from '@uifabric/react-cards';
import moment from "moment";
import { FontWeights, ITextStyles, Shimmer, ShimmerElementsGroup, ShimmerElementType } from 'office-ui-fabric-react';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CalendarContext, isFreeCard, isMeetingCard, isMeetingNoneCard, MeetingCardType, MeetingData, MeetingFree, MeetingNone } from '../../models';
import { actions } from '../../store';
import { ApiResult } from '../../store/reducers/ApiResult';
import { useSelector } from '../../store/utils';
import { allDayToday, hasMeetingException, isMeetingFinished, isMeetingStarted, isResourceOnlyMeeting } from '../../utils/meetingUtils';
import { MeetingCard } from './MeetingCard';
import { getCardStyles } from './MeetingCard.styles';
import { MeetingFreeCard } from './MeetingFreeCard';
import css from './Schedule.module.scss';

export const MIN_DURATION_UNTIL_START = moment.duration(5, "minutes")

interface ScheduleProps {
  now: number
  calendarContext: CalendarContext
  meetings: ApiResult<MeetingData[]>
}

export const errorTextStyle: ITextStyles = {
  root: {
    fontWeight: FontWeights.semilight,
    color: SharedColors.red20,
    fontSize: '0.8rem'
  }
}


export const Schedule: React.FC<ScheduleProps> = ({ now, calendarContext, meetings }) => {
  const dispatch = useDispatch()

  const isBackgroundRefresh = useSelector(s => s.meetings.upcomingMeetings?.backgroundRefresh ?? false)

  const meetingsCards = useMemo(() => {
    if (ApiResult.isSuccess(meetings)) {
      // for (let m of meetings.value) {
      //   console.log(`${m.subject}: isResourceOnlyMeeting=${isResourceOnlyMeeting(m)}, isMeetingFinished=${isResourceOnlyMeeting(m) && isMeetingFinished(m, m.resourceMeetings![0].emailAddress)},  m.endTime < now=${m.endTime > now}, shouldShow=${shouldShowMeeting(m, now)}`, m)

      // }
      const all: MeetingCardType[] = meetings.value
        .filter(m => shouldShowMeeting(m, now))
        .map(m => ({ ...m, cardType: 'Meeting' }))
      if (calendarContext.resourceType === null || all.length === 0) return all

      // add free cards for resource calendars
      const head = now.valueOf() < all[0].startTime
        ? [makeMeetingFree("start", 0, now.valueOf(), moment(all[0].startTime).add(1, 'm').valueOf(), all[0].calendarContext)]
        : []

      const rest = all.flatMap((m, idx, data) => {
        const prev = data[idx - 1]
        if (prev && prev.endTime < m.startTime) {
          return [makeMeetingFree("block", idx, prev.endTime, m.startTime, m.calendarContext), m]
        }
        return [m]
      })

      const last = all[all.length - 1]
      const tail = moment(last.endTime) < moment(now).endOf('d')
        ? [makeMeetingFree("end", all.length, last.endTime, moment(last.endTime).add(1, 'd').startOf('day').valueOf(), last.calendarContext)]
        : []
      const cards = [...head, ...rest, ...tail]

      console.log("Meeting cards (resource)", cards)
      return cards
    } else {
      return [] as MeetingCardType[]
    }
  }, [calendarContext.resourceType, meetings, now])

  /*
    If any resource-only meetings are not yet started and we are inside the allowed start time
    then update the calendar every 5 seconds (until either the meeting is started, auto-truncated or cancelled)
  */
  useEffect(() => {
    const pendingCard = meetingsCards.find(isPendingCard)

    if (pendingCard !== undefined) {
      console.log("Setting auto-refresh because of ", pendingCard)
      const timeout = window.setTimeout(() => {
        dispatch(actions.refreshUpcomingMeetings(true))
      }, 5000)
      return () => window.clearTimeout(timeout)
    }
  }, [dispatch, calendarContext, meetingsCards])

  const onFreeClick = (free: MeetingFree) => {
    if (calendarContext.resourceType === null) return
    const now = moment().valueOf()
    const data = { start: free.startTime >= now ? free.startTime : now, email: free.calendarContext, type: calendarContext.resourceType }
    const url = `#nearby=${encodeURIComponent(JSON.stringify(data))}`
    dispatch(actions.navigateAppTo("BookIt", url))
  }

  if (
    (meetings.isLoading && isBackgroundRefresh)
    || !meetings.hasResponse) {
    return <LoadingSchedule day={moment().startOf('day')} />
  }

  return <>
    {Array.from(new Array(7))
      .map((_, offset) => moment().startOf('day').add(offset, 'days'))
      .map(day => [day, meetingsForDay(meetingsCards, day, calendarContext.email, calendarContext.resourceType !== null)] as const)
      .map(([day, meetings], idx) => <ScheduleDay meetings={meetings} day={day} key={idx} now={now} onFreeClick={onFreeClick} />)
    }
  </>
}

function shouldShowMeeting(m: MeetingData, now: number) {
  console.log("shouldShowMeeting", m)

  function showMeeting(m: MeetingData) {
    if (hasMeetingException(m) &&
        m.meetingExceptions[m.calendarContext] !== undefined) {
        return !isMeetingFinished(m, m.calendarContext)
      }
      return m.endTime > now
  }

  return (isResourceOnlyMeeting(m) && !isMeetingFinished(m, m.resourceMeetings![0].emailAddress)) ||
    (!isResourceOnlyMeeting(m) && showMeeting(m))
}

function isPendingCard(m: MeetingCardType): m is MeetingData {
  return isMeetingCard(m) && isResourceOnlyMeeting(m) && (
    !m.resourceMeetings?.[0]?.id
    || (m.autoStart && !isMeetingStarted(m, m.resourceMeetings![0].emailAddress) && moment.duration(moment(m.startTime).diff(moment())) < MIN_DURATION_UNTIL_START))
}

function meetingsForDay(meetingsCards: MeetingCardType[], day: moment.Moment, calendarContext: string, showNoneAsFree: boolean): MeetingCardType[] {
  const meetings = meetingsCards.filter(m => moment(m.startTime) < day.endOf('day') && day.startOf('day') < moment(m.endTime))
  const allDay = allDayToday(day.valueOf())
  if (meetings.length === 0) {
    return [showNoneAsFree
      ? makeMeetingFree("start", day.valueOf(), allDay[0], allDay[1], calendarContext)
      : makeMeetingNone(day.valueOf(), day.valueOf(), calendarContext)
    ]
  }
  return meetings
}

function makeMeetingFree(prefix: string, key: number, start: number, end: number, calendarContext: string): MeetingFree {
  return { id: `free-${prefix}-${key}`, startTime: start, endTime: end, calendarContext, cardType: 'MeetingFree' }
}

function makeMeetingNone(key: number, now: number, email: string): MeetingNone {
  const allDay = allDayToday(now)
  return { id: `none-${key}`, startTime: allDay[0], endTime: allDay[1], cardType: 'MeetingNone', calendarContext: email }
}


interface ScheduleDayProps {
  meetings: MeetingCardType[]
  day: moment.Moment
  now: number
  onFreeClick: (meeting: MeetingFree) => void
}
export const ScheduleDay: React.FC<ScheduleDayProps> = ({ meetings, day, now, onFreeClick }) => {

  // console.log(`Schedule ${day.format('ddd')}: `, meetings)

  return <div className={css.scheduleDay}>
    <DayHeader day={day} />
    {meetings.map((meeting, idx) =>
      isFreeCard(meeting)
        ? <MeetingFreeCard meeting={meeting} key={idx} onClick={onFreeClick} />
        : isMeetingCard(meeting)
          ? <MeetingCard meeting={meeting} key={idx} index={idx} allMeetings={meetings} now={now} pending={isPendingCard(meeting)} />
          : isMeetingNoneCard(meeting)
            ? <MeetingNoneCard day={day} key={idx} />
            : null
    )}
  </div>
}

function MeetingNoneCard(props: { day: moment.Moment }) {
  return <Card aria-label="Coming Up Next" styles={getCardStyles()} className={css.noMeetingCard}>
    <Card.Section className={css.noMeetings}>
      {moment().isSame(props.day, "day") ? "No upcoming meetings" : "No meetings"}
    </Card.Section>
  </Card>
}

const wrapperStyle = { display: 'flex' }

const getMeetingShimmer = (): JSX.Element => {
  return (
    <div style={wrapperStyle}>
      < ShimmerElementsGroup shimmerElements={[
        { type: ShimmerElementType.circle, height: 48 },
        { type: ShimmerElementType.gap, width: 10, height: 48 },
      ]}
      />
      <ShimmerElementsGroup
        flexWrap
        width={'calc(100% - 58px)'}
        shimmerElements={[
          { type: ShimmerElementType.line, width: '50%', height: 12 },
          { type: ShimmerElementType.gap, width: '10%', height: 24 },
          { type: ShimmerElementType.line, width: '40%', height: 12 },
          { type: ShimmerElementType.line, width: '30%', height: 12 },
          { type: ShimmerElementType.gap, width: '70%', height: 24 },

        ]}
      />
    </div>
  );
}

export const LoadingSchedule: React.FC<{ day: moment.Moment }> = ({ day }) => {
  return <div className={css.scheduleDay}>
    <DayHeader day={day} />
    <Card aria-label="Loading schedule" styles={getCardStyles()} className={css.noMeetingCard}>
      <Card.Section className={css.noMeetings}>
        <Shimmer customElementsGroup={getMeetingShimmer()} />
      </Card.Section>
    </Card>
  </div>
}

function DayHeader({ day }: { day: moment.Moment }) {
  return <div className={css.header}>
    <span className={css.date}>{day.format('MMM D')}</span>
    <span className={css.day}>{formatDay(day)}</span>
  </div>
}

function formatDay(day: moment.Moment) {
  if (moment().isSame(day, "day")) return "Today"
  if (moment().add(1, "day").isSame(day, "day")) return "Tomorrow"

  return day.format('dddd')
}













