import { AuthenticationProvider } from './AuthenticationProvider';
import { AuthenticationProviderOptions } from './AuthenticationProviderOptions';
import { MiddlewareOptions } from './MiddlewareOptions';
export class AuthenticationHandlerOptions implements MiddlewareOptions {
    public authenticationProvider: AuthenticationProvider;
    public authenticationProviderOptions?: AuthenticationProviderOptions;
    public constructor(authenticationProvider: AuthenticationProvider, authenticationProviderOptions?: AuthenticationProviderOptions) {
		this.authenticationProvider = authenticationProvider;
		this.authenticationProviderOptions = authenticationProviderOptions;
	}
}


