import { AuthenticationHandlerOptions } from './AuthenticationHandlerOptions';
import { AuthenticationProvider } from './AuthenticationProvider';
import { AuthenticationProviderOptions } from './AuthenticationProviderOptions';
import { Context } from './Context';
import { Middleware } from './Middleware';
import { MiddlewareControl } from "./MiddlewareControl";
import { appendRequestHeader } from './MiddlewareUtils';

export class AuthenticationHandler implements Middleware {
    private static AUTHORIZATION_HEADER: string = "Authorization";
    private authenticationProvider: AuthenticationProvider;
    private nextMiddleware?: Middleware;
    public constructor(authenticationProvider: AuthenticationProvider) {
        this.authenticationProvider = authenticationProvider;
    }

    public async execute(context: Context): Promise<void> {
        try {
            if (this.nextMiddleware === undefined) {
                const error = new Error();
                error.name = "InvalidMiddleware";
                error.message = "Unable to execute the middleware the nextMiddleware property isn't set";
                throw error;
            }
            let options: AuthenticationHandlerOptions|undefined = undefined
            if (context.middlewareControl instanceof MiddlewareControl) {
                options = context.middlewareControl.getMiddlewareOptions(AuthenticationHandlerOptions) as AuthenticationHandlerOptions
            }
            let authenticationProvider: AuthenticationProvider|undefined
            let authenticationProviderOptions: AuthenticationProviderOptions|undefined
            if (typeof options !== "undefined") {
                authenticationProvider = options.authenticationProvider
                authenticationProviderOptions = options.authenticationProviderOptions
            }
            if (typeof authenticationProvider === "undefined") {
                authenticationProvider = this.authenticationProvider
            }
            const token: string = await authenticationProvider.getAccessToken(authenticationProviderOptions)
            const bearerKey: string = `Bearer ${token}`
            appendRequestHeader(context.request, context.options, AuthenticationHandler.AUTHORIZATION_HEADER, bearerKey)
            return await this.nextMiddleware.execute(context)
        }
        catch (error) {
            throw error
        }
    }

    public setNext(next: Middleware): void {
        this.nextMiddleware = next;
    }
}
