import { createAction, createAsyncAction } from 'typesafe-actions';
import { VideoFxRoomClient } from '../api/VideoFxRoomClient';
import { LaunchAction } from '../auth/applicationHostingClient';
import { AppType, CalendarContext, MeetingData, NearbyTap, QuickBookBooking, QuickBookTap, ResourceType, StartVideoFxMeetingModel, TagScannedResponse, TeamInfo, UserAccount, VideoFxRoomTap, WhoAmI } from '../models';


export const createClients = createAsyncAction('CREATE_CLIENTS_REQUEST', 'CREATE_CLIENTS_SUCCESS', 'CREATE_CLIENTS_FAILURE')
                                                <undefined, UserAccount, Error>()

export const createRoomApiClient = createAsyncAction(
    ['CREATE_ROOM_API_CLIENT_REQUEST', (organisationId: string, roomId: string) => ({organisationId, roomId})], 
    ['CREATE_ROOM_API_CLIENT_SUCCESS', (organisationId: string, roomId: string, client: VideoFxRoomClient) => ({organisationId, roomId, client})], 
    ['CREATE_ROOM_API_CLIENT_FAILURE', (e: Error) => e])()

export const registerStartingLaunchAction = createAction('REGISTER_STARTING_LAUNCH_ACTION')<LaunchAction | null>()

export const fetchUpcomingMeetings = createAsyncAction('FETCH_UPCOMING_MEETINGS_REQUEST', 'FETCH_UPCOMING_MEETINGS_SUCCESS', 'FETCH_UPCOMING_MEETINGS_FAILURE')
        <CalendarContext, {context: CalendarContext, data: MeetingData[]}, Error>()

export const refreshUpcomingMeetings = createAction('REFRESH_UPCOMING_MEETINGS_REQUEST')<boolean>()


export const fetchUpcomingMeetingsForResource = createAsyncAction('FETCH_UPCOMING_MEETINGS_FOR_RESOURCE_REQUEST', 'FETCH_UPCOMING_MEETINGSFOR_RESOURCE__SUCCESS'
                                                                    , 'FETCH_UPCOMING_MEETINGSFOR_RESOURCE__FAILURE')
    <string, MeetingData[], Error>()

export const whoAmI =  createAction('WHO_AM_I')<WhoAmI>()
export const navigateAppTo = createAction('NAVIGATE_APP_TO', (app: AppType, path: string) => ({ app, path }))()
export const launchAction = createAction('LAUNCH_ACTION', (app: AppType, ...args: string[]) => ({ app, args }))()
export const switchCalendarContext = createAction('SWITCH_CALENDAR_CONTEXT')<CalendarContext>()
export const restoreCalendarContext = createAction('RESTORE_CALENDAR_CONTEXT')()
export const setBookItBaseUrl = createAction('SET_BOOKIT_BASE_URL')<string>()


export const videoFxRoomTap = createAction('VIDEO_FX_ROOM_TAP')<VideoFxRoomTap>()
export const nearbyTap = createAction('NEARBY_TAP')<NearbyTap>()
export const quickBookTap = createAction('QUICK_BOOK_TAP')<QuickBookTap>()

export const fetchQuickBookStatus = createAsyncAction(
    ['FETCH_QUICK_BOOK_STATUS_REQUEST', (roomEmail: string, tagType: string, durMin: number) => ({roomEmail, tagType, durMin}) ],
    ['FETCH_QUICK_BOOK_STATUS_SUCCESS', (data: QuickBookBooking) => data ], 
    ['FETCH_QUICK_BOOK_STATUS_FAILURE', (e: Error) => e])()

export const fetchQuickBookTagScanned = createAsyncAction(
    ['FETCH_QUICK_BOOK_TAG_SCANNED_REQUEST', (roomEmail: string, tagType: string, resourceType: ResourceType) => ({roomEmail, tagType, resourceType}) ],
    ['FETCH_QUICK_BOOK_TAG_SCANNED_SUCCESS', (data: TagScannedResponse) => data ], 
    ['FETCH_QUICK_BOOK_TAG_SCANNED_FAILURE', (e: Error) => e])()    

export const bookQuickBook = createAsyncAction(
    [ 'QUICK_BOOK_REQUEST', (room: string, start: number, durMin: number, resourceType: ResourceType, startNow: boolean) => ({ room, start, durMin, resourceType, startNow }) ],
    [ 'QUICK_BOOK_SUCCESS', (meeting: MeetingData) => meeting ],
    [ 'QUICK_BOOK_FAILURE', (e: Error) => e ])()
    

export const startMeeting = createAsyncAction('START_MEETING_REQUEST', 'START_MEETING_SUCCESS', 'START_MEETING_FAILURE')
    <string, void, Error>()

export const cancelMeeting = createAsyncAction('CANCEL_MEETING_REQUEST', 'CANCEL_MEETING_SUCCESS', 'CANCEL_MEETING_FAILURE')
    <string, void, Error>()

export const leaveBookItMeeting = createAsyncAction('LEAVE_BOOKIT_MEETING_REQUEST', 'LEAVE_BOOKIT_MEETING_SUCCESS', 'LEAVE_BOOKIT_MEETING_FAILURE')
    <string, void, Error>()

export const extendMeeting = createAsyncAction('EXTEND_MEETING_REQUEST', 'EXTEND_MEETING_SUCCESS', 'EXTEND_MEETING_FAILURE')
    <{id:string, durationMinutes:number}, void, Error>()

    
// VideoFx
export const startDesktopApplication = createAsyncAction('START_DESKTOP_APPLICATION_REQUEST', 'START_DESKTOP_APPLICATION_SUCCESS', 'START_DESKTOP_APPLICATION_FAILURE')
    <{organisationId: string, roomId: string, link: string}, null, Error>()
export const addTeamToRoom = createAsyncAction('ADD_TEAM_TO_ROOM_REQUEST', 'ADD_TEAM_TO_ROOM_SUCCESS', 'ADD_TEAM_TO_ROOM_FAILURE')
    <{organisationId: string, roomId: string, team: TeamInfo}, null, Error>()
export const leaveMeeting = createAsyncAction('LEAVE_MEETING_REQUEST', 'LEAVE_MEETING_SUCCESS', 'LEAVE_MEETING_FAILURE')
    <{organisationId: string, roomId: string, link: string}, null, Error>()
export const startVfxMeeting = createAsyncAction('START_VFX_MEETING_REQUEST', 'START_VFX_MEETING_SUCCESS', 'START_VFX_MEETING_FAILURE')
    <{organisationId: string, roomId: string, link: string, body:StartVideoFxMeetingModel}, null, Error>()


export const startApiCallForMeeting = createAction('START_API_CALL_FOR_MEETING')<string>()
export const finishApiCallForMeeting = createAction('FINISH_API_CALL_FOR_MEETING')<string>()
export const reportApiError = createAction('REPORT_API_ERROR')<Error>()
export const dismissApiError = createAction('DISMISS_API_ERROR')()


