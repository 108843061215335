import { NeutralColors } from "@uifabric/fluent-theme"
import { FontWeights, IconButton, Image, ITextStyles, Text } from "office-ui-fabric-react"
import React, { useCallback, useState } from "react"
import "./ResourceInformation.scss"


interface ResourceInformationProps {
    informationText: string
    photoUrl?: string
    mapUrl?: string
}

const imageTitleTextStyle: ITextStyles = {
    root: {
        fontWeight: FontWeights.semilight,
        color: NeutralColors.gray140,
        marginBottom: 8
    }
}

const infoTextStyle: ITextStyles = {
    root: {
        fontWeight: FontWeights.semilight,
        color: NeutralColors.gray100,
        marginTop: 8
    }
}
export const ResourceInformation: React.FC<ResourceInformationProps> = ({ informationText, photoUrl, mapUrl }) => {

    const [showMap, setShowMap] = useState<boolean | null>(Boolean(mapUrl) ? true : Boolean(photoUrl) ? false : null)

    const handleShowMapToggle = useCallback(() => {
        setShowMap(!showMap)
    }, [showMap])

    return <div className="ResourceInformation">
        <Text></Text>
        {showMap === true && <>
            <Text styles={imageTitleTextStyle}>Map</Text>
            <Image className="mapImage" src={mapUrl} width="100%" alt="Resource map" />
        </>}
        {showMap === false && <>
            <Text styles={imageTitleTextStyle}>Photo</Text>
            <Image src={photoUrl} width="100%" alt="Photo image" />
        </>}
        <Text styles={infoTextStyle}>{informationText}</Text>
        {photoUrl !== undefined && mapUrl !== undefined && <div className="actionButtons">
            <IconButton iconProps={{ iconName: showMap ? 'Camera' : 'MapPin' }} onClick={() => handleShowMapToggle()} />
        </div>}
    </div>
}