import { NeutralColors, SharedColors } from '@uifabric/fluent-theme'
import moment from 'moment'
import { FontWeights, Icon, ITextStyles, mergeStyles, Persona, PersonaSize, Text } from 'office-ui-fabric-react'
import React from "react"
import { MeetingData } from '../../models'
import { useSelector } from '../../store/utils'
import { getFormattedDuration, getResourceMeetingType, getResourceTypeDisplayName, isResourceOnlyMeeting } from "../../utils/meetingUtils"
import css from './MeetingInfo.module.scss'
import { ResourcePersona } from './ResourcePersonal'

interface MeetingInfoProps {
    meeting: MeetingData
    isBusy: boolean
}

export const meetingTitleTextStyle: ITextStyles = {
    root: {
        fontSize: '0.9rem',
        fontWeight: FontWeights.semibold
    }
}

const meetingLocUnknownTextStyle: ITextStyles = {
    root: {
        fontSize: '0.8rem',
        fontWeight: FontWeights.semilight,
        color: NeutralColors.gray120,
        marginLeft: 4
    }
}

const meetingLocAcceptTextStyle: ITextStyles = {
    root: {
        fontSize: '0.8rem',
        fontWeight: FontWeights.semilight,
        color: SharedColors.green20,
        marginLeft: 4
    }
}

const meetingLocDeclineTextStyle: ITextStyles = {
    root: {
        fontSize: '0.8rem',
        fontWeight: FontWeights.semilight,
        color: SharedColors.red20,
        marginLeft: 4
    }
}


const locationIconClass = mergeStyles({
    fontSize: "0.8rem",
    color: NeutralColors.gray120
});

const meetingStartTextStyle: ITextStyles = {
    root: {
        color: NeutralColors.gray110,
        fontSize: "0.8rem",
    }
}

export const MeetingInfo: React.FC<MeetingInfoProps> = ({ meeting, isBusy }) => {
    const start = moment(meeting.startTime);
    const end = moment(meeting.endTime);
    const formattedStartTime = start.format("LT")
    const duration = getFormattedDuration(start, end)

    // console.log("MeetingInfo isAllDay: ", moment.duration(end.diff(start)).days() > 0)

    const bookItBaseUrl = useSelector(s => s.hosted.bookItBaseUrl)

    const isResourceOnly = isResourceOnlyMeeting(meeting)


    return <div className={css.meetingRow}>
        {!isResourceOnly && meeting.organiser && <Persona className={css.organiser}
            size={PersonaSize.size48}
            imageUrl={bookItBaseUrl && meeting.organiser?.emailAddress ? `${bookItBaseUrl}/api/people/${meeting.organiser?.emailAddress}/photo` : undefined}
            styles={{ root: { marginRight: '8px' } }}
            imageInitials={getInitials(meeting)}
            hidePersonaDetails />}
        {isResourceOnly && <ResourcePersona resourceType={getResourceMeetingType(meeting)!!} className={css.resourceIcon} />}
        <Text styles={meetingTitleTextStyle} className={css.title}>{getSubject(meeting)}</Text>
        <div className={css.location}>
            <Icon iconName="POI" className={locationIconClass} />
            <Locations meeting={meeting} />
        </div>
        {meeting.isAllDayEvent ?
            <Text className={css.time} color={NeutralColors.gray150} styles={meetingStartTextStyle}>All-day</Text> :
            <Text className={css.time} color={NeutralColors.gray150} styles={meetingStartTextStyle}>{`${formattedStartTime} (${duration})`}</Text>
        }
    </div>
}


function getInitials(meeting: MeetingData) {
    const resourceType = getResourceMeetingType(meeting)
    if (resourceType) {
        switch (resourceType) {
            case 'MeetingRoom': return "MR"
            case 'ParkingSpace': return "PS"
            case 'HotDesk': return "HD"
            case 'Locker': return "LK"
            case 'Vehicle': return "VH"
            case 'Resource': return "RS"
        }
    }

    return (/(.)[^ ]+ (?:\(.*?\) ?)?(.)?/.exec((meeting.organiser?.name ?? "")) || []).slice(1).join('').toUpperCase()
}


function getSubject(meeting: MeetingData) {
    const resourceType = getResourceMeetingType(meeting)
    if (resourceType) {
        return `${getResourceTypeDisplayName(resourceType)} Booking`
    }
    return meeting.subject
}

function Locations(props: { meeting: MeetingData }): JSX.Element {
    const { meeting } = props
    const resourceType = getResourceMeetingType(meeting)
    if (resourceType) {
        return <Text styles={getRoomStyle(meeting.resourceMeetings![0].responseStatus)} >{meeting.resourceMeetings![0].name}</Text>
    } else if (meeting.meetingRooms.length > 0) {
        return <>
            {meeting.meetingRooms.map(r =>
                <Text styles={getRoomStyle(r.responseStatus)} key={r.emailAddress}>{r.name}</Text>)}
        </>
    } else {
        return <Text styles={meetingLocUnknownTextStyle}>Online</Text>
    }
}

function getRoomStyle(responseStatus: string | any) {
    switch (responseStatus) {
        case "Unknown":
        case "NoResponse":
        case "Tentative":
            return meetingLocUnknownTextStyle
        case "Accepted":
            return meetingLocAcceptTextStyle
        case "Rejected":
            return meetingLocDeclineTextStyle
        default:
            return meetingLocUnknownTextStyle

    }
}
