import { Card } from "@uifabric/react-cards"
import classNames from "classnames"
import { ActionButton, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Spinner, SpinnerSize } from "office-ui-fabric-react"
import React, { useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import { MeetingCardType, MeetingData } from "../../models"
import { requireUserAccount } from "../../store/selectors"
import { actions } from "../../store/types"
import { useSelector } from "../../store/utils"
import { meetingOperations } from "../../utils/meetingOperations"
import { isResourceOnlyMeeting } from "../../utils/meetingUtils"
import { AvailableDurationSelector } from "./AvailableDurationSelector"
import './MeetingCard.scss'
import { footerCardSectionStyles, footerCardSectionTokens, getCardStyles } from "./MeetingCard.styles"
import { MeetingInfo } from "./MeetingInfo"
import { ResourceInformation } from "./ResourceInformation"

const confirmCancelDialogContentProps = {
    type: DialogType.normal,
    title: 'Cancel Booking',
    subText: 'Are you sure you want to cancel this booking?'
}

const extendUnavailableDialogProps = {
    type: DialogType.normal,
    title: 'Extend booking',
    subText: 'There isn\'t sufficient time available until the next booking to extend this meeting.'
}

const extendDialogContentProps = {
    type: DialogType.normal,
    title: 'Extend booking',
    subText: 'Select the duration:'
}

const modalPropsStyles = { main: { maxWidth: 450 } };

const modalProps = {
    isBlocking: true,
    styles: modalPropsStyles
}

const infoModalProps = {
    isBlocking: true,
    topOffsetFixed: true,
    styles: modalPropsStyles
}

type MeetingCardProps = {
    meeting: MeetingData,
    allMeetings: MeetingCardType[],
    index: number,
    now: number,
    pending: boolean
}


export const MeetingCard: React.FC<MeetingCardProps> = ({ meeting, allMeetings, index, now, pending }) => {
    const myself = useSelector(s => requireUserAccount(s).emailAddress)

    const calendarContext = useSelector(s => s.meetings.upcomingMeetings?.context)

    const apiCalls = useSelector(s => s.meetings.apiCalls)
    const isBusy = useMemo(() => {
        const busy = apiCalls[meeting.id]
        if (busy) return true
        if (isResourceOnlyMeeting(meeting)) {
            const busy = meeting.resourceMeetings[0].id && apiCalls[meeting.resourceMeetings[0].id]
            return Boolean(busy)
        }
        return false
    }, [apiCalls, meeting])

    const meetingOps = useMemo(() => meetingOperations(now, myself, meeting, calendarContext, index, allMeetings)
        , [now, myself, meeting, calendarContext, index, allMeetings])

    const dispatch = useDispatch()

    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false)
    const handleShowConfirmCancelDialog = useCallback((show: boolean) => {
        setShowConfirmCancelDialog(show)
    }, [])

    const handleCancelMeeting = useCallback(() => {
        handleShowConfirmCancelDialog(false)
        dispatch(actions.cancelMeeting.request(meeting.id))
    }, [dispatch, handleShowConfirmCancelDialog, meeting.id])


    const [showExtendDialog, setShowExtendDialog] = useState(false)
    const [showInfoDialog, setShowInfoDialog] = useState(false)
    const [duration, setDuration] = useState(15)
    const [showExtendUnavailableDialog, setShowExtendUnavailableDialog] = useState(false)

    const handleDurationChange = (duration: number) => {
        setDuration(duration)
    }

    const handleShowExtendDialog = useCallback((show: boolean) => {
        setShowExtendDialog(show)
    }, [])

    const handleExtendMeeting = useCallback(() => {
        setShowExtendDialog(false)
        if (meeting.id) {
            dispatch(actions.extendMeeting.request({ id: meeting.id, durationMinutes: duration }))
        }
    }, [dispatch, duration, meeting.id])

    const handleShowExtendUnavailableDialog = useCallback((show: boolean) => {
        setShowExtendUnavailableDialog(show)
    }, [])

    const handleExtendMeetingUnavailable = useCallback(() => {
        setShowExtendDialog(false)
        handleShowExtendUnavailableDialog(true)
    }, [handleShowExtendUnavailableDialog])

    const handleShowInfoDialog = useCallback((show: boolean) => {
        setShowInfoDialog(show)
    }, [])

    const handleStartMeeting = useCallback((evt) => {
        if (meetingOps.startMeetingId) {
            dispatch(actions.startMeeting.request(meetingOps.startMeetingId))
        }
    }, [dispatch, meetingOps.startMeetingId])

    const handleLeaveMeeting = useCallback((evt) => {
        if (meetingOps.leaveMeetingId) {
            dispatch(actions.leaveBookItMeeting.request(meetingOps.leaveMeetingId))
        }

    }, [dispatch, meetingOps.leaveMeetingId])

    function handleAddResources() {
        dispatch(actions.navigateAppTo("BookIt", `#addResources=${encodeURIComponent(meeting.id)}`))
    }

    return <>

        <Dialog
            hidden={!showConfirmCancelDialog}
            onDismiss={() => handleShowConfirmCancelDialog(false)}
            dialogContentProps={confirmCancelDialogContentProps}
            modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={() => handleCancelMeeting()} text="Yes" />
                <DefaultButton onClick={() => handleShowConfirmCancelDialog(false)} text="No" />
            </DialogFooter>
        </Dialog>

        {showExtendUnavailableDialog &&
            <Dialog
                hidden={!showExtendUnavailableDialog}
                onDismiss={() => handleShowExtendUnavailableDialog(false)}
                dialogContentProps={extendUnavailableDialogProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={() => handleShowExtendUnavailableDialog(false)} text="Ok" />
                </DialogFooter>
            </Dialog>
        }

        {isResourceOnlyMeeting(meeting) && <Dialog
            hidden={!showInfoDialog}
            onDismiss={() => handleShowInfoDialog(false)}
            dialogContentProps={{ type: DialogType.normal, title: `${meeting.resourceMeetings![0].name} Information` }}
            modalProps={infoModalProps}>
            <ResourceInformation
                informationText={meeting.resourceMeetings![0].emailText!}
                mapUrl={meeting.resourceMeetings![0].floorPlanUrl}
                photoUrl={meeting.resourceMeetings![0].photoUrl}
            />
        </Dialog>}
        {meetingOps.canExtend && <Dialog
            hidden={!showExtendDialog}
            onDismiss={() => handleShowExtendDialog(false)}
            dialogContentProps={extendDialogContentProps}
            modalProps={modalProps}>
            <AvailableDurationSelector currentMeeting={meeting} 
                    resourceEmailAddress={calendarContext?.resourceType !== undefined ? calendarContext.email : meeting.meetingRooms[0].emailAddress}
                    onDurationChange={handleDurationChange}
                    onUnavailable={handleExtendMeetingUnavailable}
              />
            <DialogFooter>
                <PrimaryButton onClick={() => handleExtendMeeting()} text="Extend" />
                <DefaultButton onClick={() => handleShowExtendDialog(false)} text="Cancel" />
            </DialogFooter>
        </Dialog>}
        <Card aria-label="Coming Up Next" styles={getCardStyles()} className={classNames("MeetingCard", meetingOps.showIsStarted && !pending && "started", pending && "pending")}>
            <Card.Section className='meetings'>
                <MeetingInfo meeting={meeting} isBusy={isBusy} />
            </Card.Section>

            {(meetingOps.canCancel || meetingOps.canExtend || meetingOps.canStart || meetingOps.canLeave || meetingOps.canEdit || meetingOps.canAddResources) &&
                <Card.Section horizontal styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
                    {/* {meetingOps.canEdit && <ActionButton>
                        <svg viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                    </ActionButton>} */}
                    {meetingOps.canAddResources && <ActionButton onClick={handleAddResources}>
                        <svg viewBox="0 0 20 20"><path stroke="none" d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 8a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zm6-6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zm3 6a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z"/></svg>
                    </ActionButton>}
                    {meetingOps.canCancel && <ActionButton onClick={() => handleShowConfirmCancelDialog(true)}>
                        <svg viewBox="-1 -1 50 50"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4v-24h-24v24zm26-30h-7l-2-2h-10l-2 2h-7v4h28v-4z" /></svg>
                    </ActionButton>}
                    {meetingOps.canExtend && <ActionButton onClick={() => handleShowExtendDialog(true)}><svg viewBox="0 0 335.47 364.01"><path d="M280.65,117v23.45A127.12,127.12,0,0,1,166.84,324.32c-70.11,0-127.16-57-127.16-127.15,0-65.12,49.21-118.95,112.38-126.3a20.55,20.55,0,0,1,19.88-15.45H219V38.69a166.3,166.3,0,0,0-52.19-8.37C74.85,30.32,0,105.17,0,197.17S74.85,364,166.84,364s166.84-74.85,166.84-166.84A165.88,165.88,0,0,0,313.15,117Z M85.66,145.41a15.56,15.56,0,0,0,0,21.92l74.08,74.08v0h0l0,0,65.82-21.21a15.5,15.5,0,0,0-9.51-29.51l-47.83,15.42-60.7-60.7A15.56,15.56,0,0,0,85.66,145.41Z M322.51,66.19H269.28V13A13,13,0,0,0,256.32,0H243.71a13,13,0,0,0-13,13V66.19H177.52a13,13,0,0,0-13,13V91.76a13,13,0,0,0,13,12.95h53.24V158a13,13,0,0,0,13,13h12.61a13,13,0,0,0,13-13V104.71h53.23a13,13,0,0,0,13-12.95V79.14A13,13,0,0,0,322.51,66.19Z" /></svg></ActionButton>}
                    {meetingOps.canStart && <ActionButton onClick={handleStartMeeting}><svg viewBox="-582 -582 4500 4500"><path d="M635 2039l271 270c154,-146 280,-289 426,-434 140,-138 183,-189 338,-313 144,-114 276,-5 31,242 -72,72 -142,137 -214,217 -64,70 -419,390 -439,429l254 257c107,-33 730,-498 864,-612l460 -430c166,-151 394,-485 506,-677 64,-108 132,-278 169,-426 23,-95 69,-457 -9,-515 -46,-35 -180,-48 -248,-47 -91,1 -173,16 -258,38 -280,75 -555,237 -778,404 -572,427 -1002,984 -1373,1597zm800 672c23,109 48,178 58,298 10,109 3,239 15,343l244 -186c78,-65 166,-140 230,-210 135,-148 312,-306 312,-598 0,-70 -16,-207 -48,-254 -224,203 -523,442 -811,607zm-1435 -870c323,4 335,2 636,71 40,-91 218,-337 283,-423 52,-69 106,-133 159,-198l164 -189c-74,-60 -340,-52 -439,-23 -214,64 -438,313 -579,468 -44,49 -201,236 -224,294zm735 779c142,-36 975,-867 1018,-1017 -102,17 -477,395 -553,470 -64,62 -457,459 -465,547z" /></svg></ActionButton>}
                    {meetingOps.canLeave && <ActionButton onClick={handleLeaveMeeting}><svg viewBox="0 0 420 420"><path d="M192 104l-85 -35 156 0 0 36 32 0 0 -36c0,-19 -16,-34 -34,-34l-193 0c-18,0 -33,15 -33,34l0 247c0,20 11,27 28,33l129 53 0 -52 69 0c18,0 34,-15 34,-34l0 -36 -32 0 0 35 -71 0 0 -211zm35 72l105 0 0 -52 70 69 -70 69 0 -52 -105 0 0 -34z" /></svg></ActionButton>}
                    {isBusy && <Spinner size={SpinnerSize.medium} />}
                    {meetingOps.canShowInfo && <ActionButton className='infoButton' onClick={() => handleShowInfoDialog(true)}><InfoIcon /></ActionButton>}
                </Card.Section>
            }
        </Card>
    </>
}
