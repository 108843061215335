import moment from 'moment'


export function ceil(input: moment.MomentInput, amount: number, unit: moment.unitOfTime.Base) {
    const m = moment(input).startOf(unit)
    const rem = m.get(unit) % amount
    return m.add((amount - rem) % amount, unit)
}


export function isMidnight(input: moment.Moment): boolean {
    return (input.hours() === 23 && input.minutes() === 59 && input.seconds() === 59)
        || (input.hours() === 0 && input.minutes() === 0 && input.seconds() === 0)
}

export function minutesTillMidnigt() {
    return moment().endOf('day').diff(moment(), "minutes")
}