import { initializeIcons } from '@uifabric/icons';
import { ConnectedRouter } from 'connected-react-router';
import 'moment/locale/en-nz';
import { createTheme, mergeStyles, registerIcons } from 'office-ui-fabric-react';
import { ThemeProvider } from 'office-ui-fabric-react/lib/Foundation';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';
import { loadUser, OidcProvider } from 'redux-oidc';
import userManager from './auth/userManager';
import { App } from './components/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { history, store } from './store';
import { RefreshContextProvider } from './store/RefreshProvider';

initializeIcons();

const theme = createTheme({
    palette: {
        themePrimary: '#6264a7',
        themeLighterAlt: '#f7f7fb',
        themeLighter: '#e1e1f1',
        themeLight: '#c8c9e4',
        themeTertiary: '#989ac9',
        themeSecondary: '#7173b0',
        themeDarkAlt: '#585a95',
        themeDark: '#4a4c7e',
        themeDarker: '#37385d',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});


// Inject some global styles
mergeStyles({
    selectors: {
        ':global(body), :global(html), :global(#root)': {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});

registerIcons({
    icons: {
        'DeleteMeeting': <svg viewBox="-1 -1 50 50"><path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4v-24h-24v24zm26-30h-7l-2-2h-10l-2 2h-7v4h28v-4z" /></svg>,
        'StartMeeting': <svg viewBox="0 0 3336 3352"><path d="M635 2039l271 270c154,-146 280,-289 426,-434 140,-138 183,-189 338,-313 144,-114 276,-5 31,242 -72,72 -142,137 -214,217 -64,70 -419,390 -439,429l254 257c107,-33 730,-498 864,-612l460 -430c166,-151 394,-485 506,-677 64,-108 132,-278 169,-426 23,-95 69,-457 -9,-515 -46,-35 -180,-48 -248,-47 -91,1 -173,16 -258,38 -280,75 -555,237 -778,404 -572,427 -1002,984 -1373,1597zm800 672c23,109 48,178 58,298 10,109 3,239 15,343l244 -186c78,-65 166,-140 230,-210 135,-148 312,-306 312,-598 0,-70 -16,-207 -48,-254 -224,203 -523,442 -811,607zm-1435 -870c323,4 335,2 636,71 40,-91 218,-337 283,-423 52,-69 106,-133 159,-198l164 -189c-74,-60 -340,-52 -439,-23 -214,64 -438,313 -579,468 -44,49 -201,236 -224,294zm735 779c142,-36 975,-867 1018,-1017 -102,17 -477,395 -553,470 -64,62 -457,459 -465,547z" /></svg>,
        'LeaveMeeting': <svg viewBox="0 0 420 420"><path d="M192 104l-85 -35 156 0 0 36 32 0 0 -36c0,-19 -16,-34 -34,-34l-193 0c-18,0 -33,15 -33,34l0 247c0,20 11,27 28,33l129 53 0 -52 69 0c18,0 34,-15 34,-34l0 -36 -32 0 0 35 -71 0 0 -211zm35 72l105 0 0 -52 70 69 -70 69 0 -52 -105 0 0 -34z" /></svg>,
        'ExtendMeeting': <svg viewBox="0 0 335.47 364.01"><path d="M280.65,117v23.45A127.12,127.12,0,0,1,166.84,324.32c-70.11,0-127.16-57-127.16-127.15,0-65.12,49.21-118.95,112.38-126.3a20.55,20.55,0,0,1,19.88-15.45H219V38.69a166.3,166.3,0,0,0-52.19-8.37C74.85,30.32,0,105.17,0,197.17S74.85,364,166.84,364s166.84-74.85,166.84-166.84A165.88,165.88,0,0,0,313.15,117Z M85.66,145.41a15.56,15.56,0,0,0,0,21.92l74.08,74.08v0h0l0,0,65.82-21.21a15.5,15.5,0,0,0-9.51-29.51l-47.83,15.42-60.7-60.7A15.56,15.56,0,0,0,85.66,145.41Z M322.51,66.19H269.28V13A13,13,0,0,0,256.32,0H243.71a13,13,0,0,0-13,13V66.19H177.52a13,13,0,0,0-13,13V91.76a13,13,0,0,0,13,12.95h53.24V158a13,13,0,0,0,13,13h12.61a13,13,0,0,0,13-13V104.71h53.23a13,13,0,0,0,13-12.95V79.14A13,13,0,0,0,322.51,66.19Z" /></svg>,
    }
})

loadUser(store, userManager);

if (matchPath(window.location.pathname, "/logged-in-status/:roomName")) {
    let cur = window.location.pathname
    history.replace("/")
    history.push(cur)
}

// <Customizer {...FluentCustomizations}>
// </Customizer>

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <RefreshContextProvider>
                    <OidcProvider userManager={userManager} store={store}>
                        <App />
                    </OidcProvider>
                </RefreshContextProvider>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
