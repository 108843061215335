import { IMessageBarStyles } from "office-ui-fabric-react";

export const DefaultMessageBarStyle: IMessageBarStyles = {
    root: {
        minHeight: -1,
    },
    iconContainer: {
        minHeight: "48px",
        margin: "12px 0px 8px 12px",
    },
    icon: {
        fontSize: "14pt",
    },
    innerText: {
        fontSize: "14pt",
        lineHeight: "18pt",
    }
}