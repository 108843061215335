import { AuthenticationProvider } from '../api/AuthenticationProvider';
import { BookItClient } from '../api/BookItClient';
import { VideoFxClient } from '../api/VideoFxClient';
import { HostingClient } from '../auth/applicationHostingClient';

export interface Services {
    authProvider?: AuthenticationProvider
    hostingClient?: HostingClient
    videoFxClient?: VideoFxClient
    bookItClient?: BookItClient
}

export const services: Services = { };
