import { MiddlewareOptions } from "./MiddlewareOptions";
export class MiddlewareControl {
    private middlewareOptions: Map<Function, MiddlewareOptions>;
    public constructor(middlewareOptions: MiddlewareOptions[] = []) {
        // tslint:disable-next-line:ban-types
        this.middlewareOptions = new Map<Function, MiddlewareOptions>();
        for (const option of middlewareOptions) {
            const fn = option.constructor;
            this.middlewareOptions.set(fn, option);
        }
    }
    public getMiddlewareOptions(fn: Function): MiddlewareOptions|undefined {
        return this.middlewareOptions.get(fn);
    }
    public setMiddlewareOptions(fn: Function, option: MiddlewareOptions): void {
        this.middlewareOptions.set(fn, option);
    }
}
