import { Log } from 'oidc-client';
import { createUserManager } from 'redux-oidc';
import Config from './oidcConfig';

const silentRedirectUrl =  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${process.env.PUBLIC_URL}/oidcSilentRenew`
const userManagerConfig = {
  client_id: 'IpfxIdMobileWebApp',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${process.env.PUBLIC_URL}/oidcCallback`,
  response_type: 'token id_token',
  scope: 'openid profile profile room roomadmin server BookIt.ReadWrite.All',
  authority: Config.serverApiRoot,
  silent_redirect_uri: silentRedirectUrl,
  silentRequestTimeout: 30000,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

Log.logger = console
Log.level = Log.WARN

const userManager = createUserManager(userManagerConfig);

export default userManager;