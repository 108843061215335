import { Card } from "@uifabric/react-cards"
import moment from "moment"
import { Text } from 'office-ui-fabric-react'
import React from "react"
import { MeetingFree } from "../../models"
import { getFormattedDuration } from "../../utils/meetingUtils"
import { getCardStyles } from "./MeetingCard.styles"
import css from "./MeetingFreeCard.module.scss"


interface MeetingFreeCardProps {
    meeting: MeetingFree
    onClick: (meeting: MeetingFree) => void
}

export const MeetingFreeCard: React.FC<MeetingFreeCardProps> = ({ meeting, onClick }) => {
    const start = moment(meeting.startTime);
    const end = moment(meeting.endTime);
    const formattedStartTime = start.format("LT")
    const duration = getFormattedDuration(start, end)
    const isAllDay = moment.duration(end.diff(start)).days() > 0

    return <Card aria-label="Free" styles={getCardStyles()} className={css.meetingCardFree}
                onClick={_ => onClick(meeting)}>
        <Card.Section className={css.cardSection}>
            <div className={css.icon}>
                <span>Book</span>
            </div>
            <Text className={css.title}>Free</Text>
            {isAllDay
                ? <Text className={css.time}>All-day</Text>
                : <Text className={css.time}>{`${formattedStartTime} (${duration})`}</Text>}

        </Card.Section>
    </Card>
}