import { VideoFxMeetingList, VideoFxRoom } from '../models';
import { urlJoin } from '../utils/urlJoin';
import { AuthenticationProvider } from './AuthenticationProvider';
import { Context } from './Context';
import { HTTPClient } from './HTTPClient';
import { HTTPClientFactory } from './HTTPClientFactory';
import { RequestMethod } from './RequestMethod';

export interface VideoFxApiClientOptions {
    authProvider: AuthenticationProvider;
    baseUrl: string;
}

export class VideoFxClient {

    private httpClient: HTTPClient
    private options: VideoFxApiClientOptions;

    public static create(clientOptions: VideoFxApiClientOptions): VideoFxClient {
        return new VideoFxClient(clientOptions);

    }

    public static createWithAuthProvider(baseUrl: string, authProvider: AuthenticationProvider): VideoFxClient {
        const opts = { baseUrl, authProvider }
        return new VideoFxClient(opts);
    }

    private constructor(clientOptions: VideoFxApiClientOptions) {
        if (!clientOptions.baseUrl || clientOptions.baseUrl === "") {
            throw Error("Must set the baseUrl on VideoFxApiClientOptions")
        }
        this.httpClient = HTTPClientFactory.createWithAuthenticationProvider(clientOptions.authProvider)
        this.options = clientOptions

    }

    // public createConsentObservable(upn: string, consent: CreateConsent): Observable<Consent> {
    //     return from(this.createConsent(upn, consent))
    // }

    // public async createConsent(upn: string, consent: CreateConsent): Promise<Consent> {
    //     return this.post<Consent>(this.getUrl(`users/${upn}/consents`),
    //         { 'Content-Type': 'application/json' },
    //         JSON.stringify(consent))
    // }

    // public async cancelConsent(consentId: number) {
    //     return this.postNoContent(this.getUrl(`consents/${consentId}`))
    // }

    public async fetchVideoFxUserMeetings(emailAddress: string): Promise<VideoFxMeetingList> {        
        const url = this.getUrl(`users/${emailAddress}/meetings`)
        return this.get<VideoFxMeetingList>(url)
    }

    // public async launchAdhocMeeting(emailAddress: string): Promise<Boolean> {

    //     console.log('httpClient', this.httpClient)
    //     const url = this.getUrl(`conversations`)
    //     const hdr = { 'Content-Type': 'application/json' }

    //     const token = await this.options.authProvider.getAccessToken()
    //     const body = {
    //         'conversationType': 'StartTeamsAdhocAsUser',
    //         'meetNowType': 'Teams',
    //         'adHocDurationMinutes': '30',
    //         'signInAddress': emailAddress,
    //         'bearerToken' : token }

    //     return this.post<Boolean>(url, hdr, JSON.stringify(body))
    // }

    public async fetchRoom(organisationId:string, roomId: string) {
        const url = this.getUrl(`Organisations/${organisationId}/rooms/${roomId}`);
        console.log('fetchRoom: ', url)
        return this.get<VideoFxRoom>(url)
    }

    private getUrl(path: string) {
        return urlJoin([this.options.baseUrl, path])
    }

    private async get<TResponse>(request: RequestInfo): Promise<TResponse> {
        const options: RequestInit = {
            method: RequestMethod.GET
        };
        const context = await this.send(options, request, (status: number) => status < 400)
        return await context.response!.json() as TResponse
    }

    private async post<TResponse>(request: RequestInfo, headers: HeadersInit, body: any): Promise<TResponse> {
            const options: RequestInit = {
                method: RequestMethod.POST,
                headers: headers,
                body: body
            };
            const context = await this.send(options, request, (status: number) => status < 400)
            return await context.response!.json() as TResponse
    }

    private async postNoContent(request: RequestInfo, headers: HeadersInit = {}) {
        const options: RequestInit = {
            method: RequestMethod.POST,
            headers: headers,
        };
        await this.send(options, request, (status: number) => status === 204)
    }

    private async send(options: RequestInit, request: RequestInfo, expectedStatus: (status: number) => boolean): Promise<Context> {
        let error: Error|undefined
        try {
            const context = await this.httpClient.sendRequest({ request, options })

            if (context.response) {
                if (expectedStatus(context.response.status)) {
                    return context
                }
                console.error(`${options.method} ${request} HTTP error: ${context.response.status} ${context.response.statusText}`)
                const clientError = new ClientError("HTTP Error", context.response.status)
                clientError.response = context.response
                error = clientError
            } else {
                error = new ClientError("Unknown error")
            }
        } catch (error) {
            console.error(`${options.method} ${request} failed`, error)
            throw error
        }
        throw error
    }
}

export class ClientError extends Error {
    public statusCode: number;
    public code: string | null;
    public response: Response | null;

    public constructor(message: string, statusCode: number = -1) {
        super(message)
        this.statusCode = statusCode;
        this.code = null;
        this.response = null
    }
}

