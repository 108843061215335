import { getType } from "typesafe-actions";
import { VideoFxRoomTap } from "../../models";
import { Actions, actions } from "../types";


export interface VideoFxState {
    roomTapped?: VideoFxRoomTap
}

export default function videofx(state: VideoFxState = {}, action: Actions): VideoFxState {
    switch (action.type) {
        case getType(actions.videoFxRoomTap):
            return { ...state, roomTapped: action.payload }
        default:
            return state
    }
}
