import moment from 'moment';
import { CalendarBatchModel, Contact, MeetingData, QuickBookArgs, QuickBookBooking, ResourceType, TagScannedResponse, WhoAmI } from '../models';

export class HttpError extends Error {
    public readonly status: number;
    public readonly statusText: string;
    public readonly body: any;

    constructor(status: number, statusText: string, body: any) {
        super(`${status} ${statusText}`);

        this.status = status;
        this.statusText = statusText;
        this.body = body;

        Object.setPrototypeOf(this, HttpError.prototype);
    }
}

export async function createBookItClient(baseUrl: string) {

    let myself: Contact|null = null
    return {

        setMyself(me: Contact) {
            myself = me
        },

        async getUpcomingMeetings(email: string) {
            const now = Date.now()
            const midnightToday =  moment(now).startOf('d')
            const meetings = await fetchTimeline(midnightToday.valueOf(), moment(now).endOf('d').add(6, 'days').valueOf(), email)
            return meetings || null
        },

        async getUpcomingMeetingsForResource(email: string) {
            const start = moment().startOf('d').valueOf();
            const end = moment().endOf('d').valueOf()
            const meetings = await fetchTimeline(start, end, email)
            const now = Date.now()
            return meetings.filter(m => m.endTime >= now) || null
        },

        // async getCurrentResourceBooking(resourceEmail:string) {
        //     const args: QuickBookArgs = {
        //         sourceEmail: resourceEmail
        //     }
        //     return await post<MeetingData>('api/calendar/quickBook/currentResourceBooking', args)
        // },

        async getQuickBookStatus(email: string, tagType: string, durationMinutes: number) {
            const args: QuickBookArgs = {
                sourceEmail: email,
                tagType: tagType,
                durationMinutes: durationMinutes
            }
            return await post<QuickBookBooking>('api/calendar/quickBook/closestAvailableResource', args) ?? null
        },

        async bookResource(room: string, start: number, durMin: number, startNow: boolean) {
            console.log(`bookResource: room=${room}, startNow: ${startNow}`)

            let startTime = moment(start).set({ second: 0, millisecond: 0 })
            let endTime = moment(start).set({ second: 0, millisecond: 0 }).add(durMin, 'm')

            if (durMin === -1) {
                // It's an all-day event
                startTime = startTime.startOf('d')
                endTime = startTime.add(1, 'd').startOf('d')
            }

            return await post<MeetingData>('api/calendar/meeting', {
                startTime: startTime.toDate(),
                endTime: endTime.toDate(),
                subject: 'Resource booking',
                bodyText: '',

                isPrivate: false,
                isAdHocMeeting: false,
                autoStart: startNow,

                attendees: [],
                meetingRooms: [],

                resourceMeetings: [{
                    startOffsetMins: 0,
                    endOffsetMins: 0,
                    emailAddresses: [room],
                }]
            });
        },

        async bookRoom(room: string, start: number, durMin: number, startNow: boolean) {
            console.log(`bookRoom: room=${room}, startNow: ${startNow}`)

            let startTime = moment(start).set({ second: 0, millisecond: 0 })
            let endTime = moment(start).set({ second: 0, millisecond: 0 }).add(durMin, 'm')

            if (durMin === -1) {
                // It's an all-day event
                startTime = startTime.startOf('d')
                endTime = startTime.add(1, 'd').startOf('d')
            }

            return await post<MeetingData>('api/calendar/meeting', {
                startTime: startTime.toDate(),
                endTime: endTime.toDate(),
                subject: 'Quick Book',
                bodyText: '',

                onlineMeetingTypes: ['Teams'],
                isPrivate: false,
                isAdHocMeeting: false,
                autoStart: startNow,

                attendees: [],
                meetingRooms: [room],
                resourceMeetings: []
            });
        },

        async startMeeting(id: string) {
            await post(`api/calendar/meeting/${encodeURIComponent(id)}/actualStart`, {})
        },

        async cancelMeeting(id: string) {
            await del(`api/calendar/meeting/${encodeURIComponent(id)}`, { userName: myself!.emailAddress });
        },

        async extendMeeting(id: string, durationMinutes: number) {
            await post(`api/calendar/meeting/extend/${encodeURIComponent(id)}`, { minutes: durationMinutes })
        },

        async leaveMeeting(id: string) {
            await post(`api/calendar/meeting/${encodeURIComponent(id)}/actualEnd`, {})
        },

        async quickBookTagScanned(roomEmail: string, resourceType: ResourceType) {
            return await post<TagScannedResponse>(`api/locationcode/scanned`, { resourceEmail: roomEmail, resourceType })
        },
        async bearerLogin(token: string) {
            try {
                return await post<WhoAmI>('api/login/bearer', null, { Authorization: `Bearer ${token}` });
            } catch (e) {
                if (e instanceof HttpError && e.status === 401) {
                    return false;
                }
                throw e;
            }
        },
        async whoAmI() {
            try {
                return await get<WhoAmI>('api/whoAmI')
            } catch (e) {
                if (e instanceof HttpError && e.status === 401) {
                    return false;
                }
                throw e;
            }
        }
    }

    function getUrl(url: string) {
        return `${baseUrl}/${url}`
    }

    

    // Calls GetcCalendars, which takes and returns arrays. We only use for
    // singular cases, so handle the [].
    async function fetchTimeline(start: number, end: number, email: string) {

        const body = {
            emailAddress: email,
            start: moment(start).toDate(),
            end: moment(end).toDate()
        };

        try {

            const calendars = await post<CalendarBatchModel>('api/calendar/calendars',body)
            const meetings = calendars.meetings

            // All-day events need the timezone part removed
            meetings.forEach(m => {
                if (m.isAllDayEvent) {
                    m.startTime = trimAllDay(m.startTime)
                    m.endTime = trimAllDay(m.endTime)
                }
            })

            console.log('fetchTimeline', meetings)
            return meetings

        } catch (e) {
            if (e instanceof HttpError && e.status === 404) {
                return [];
            } else {
                throw e;
            }
        }
    }

    function trimAllDay(time: number) {
        const [y, m, d] = moment.utc(time).toArray()
        return moment([y, m, d]).valueOf()
    }

    async function fetchJson<T>(url: string, { headers, ...init }: RequestInit) {
        const response = await fetch(getUrl(url), {
            credentials: 'include',
            ...init,
            headers: {
                ...headers,
                'Accept': 'application/json',
            }
        });
        if (response.status === 204) {
            return undefined! as T;
        }
        if (response.status >= 400) {
            let body = await response.text();
            try {
                const json = await JSON.parse(body)
                body = json
            } catch (e) {
                ;
            }
            throw new HttpError(response.status, response.statusText, body);
        }
        return await response.json() as T;
    }

    async function get<T>(url: string) {
        return await fetchJson<T>(url, {});
    }

    async function post<T>(url: string, body: any, headers: Record<string, string> = {}) {
        return await fetchJson<T>(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(body)
        });
    }

    async function del<T>(url: string, body: any, headers: Record<string, string> = {}) {
        return await fetchJson<T>(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', ...headers },
            body: JSON.stringify(body)
        });
    }
}

export type BookItClient = ReturnType<typeof createBookItClient> extends Promise<infer R> ? R : never