export const appendRequestHeader = (request: RequestInfo, options: RequestInit | undefined, key: string, value: string): void => {
	if (typeof Request !== "undefined" && request instanceof Request) {
		(request as Request).headers.append(key, value);
	} else if (typeof options !== "undefined") {
		if (options.headers === undefined) {
			options.headers = new Headers({
				[key]: value,
			});
		} else {
			if (typeof Headers !== "undefined" && options.headers instanceof Headers) {
				(options.headers as Headers).append(key, value);
			} else if (options.headers instanceof Array) {
				(options.headers as string[][]).push([key, value]);
			} else if (options.headers === undefined) {
				options.headers = { [key]: value };
			} else {
				const hdr = options.headers as any
				if (hdr[key] === undefined) {
					hdr[key] = value;
				} else {
					hdr[key] += `, ${value}`;
				}
			}
		}
	}
};