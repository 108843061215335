import { HttpError } from "../../api/BookItClient"

export interface ApiError {
    exception?: Error
    problemDetails?: ProblemDetails
    message?: string
}

export interface ProblemDetails {
    code: string;
    detail?: string;
    instance: string;
    status: number;
    title: string;
    type: string;
}

type ApiResultBase = Readonly<{ isLoading: boolean, isRequested: boolean }>

type ApiSuccessResult<T> = ApiResultBase &  Readonly<{
    hasResponse: true
    error?: undefined
    value: T
}>

type ApiFailureResult = ApiResultBase & Readonly<{
    hasResponse: true
    error: ApiError
    value?: undefined
}>

type ApiNoDataResult =  ApiResultBase & Readonly<{ hasResponse: false }>

export type ApiResult<T> = ApiNoDataResult
        | ApiSuccessResult<T>
        | ApiFailureResult

export const ApiResult = {
    default<T>(): ApiResult<T> {
        return {
            isRequested: false,
            isLoading: false,
            hasResponse: false
        }
    },
    loading<T>(state: ApiResult<T>): ApiResult<T> {
        return {
            ...state,
            isRequested: true,
            isLoading: true
        }
    },
    success<T>(value: T): ApiResult<T> {
        return {
            isLoading: false,
            isRequested: true,
            hasResponse: true,
            value
        }
    },
    failure<T>(error: Error): ApiResult<T> {
        console.log("generating Api Failure object")
        return {
            isLoading: false,
            isRequested: true,
            hasResponse: true,
            error: getApiResult(error)
        }
    },
    getValueOrThrow<T>(result: ApiResult<T>): T {
        if (this.isSuccess(result)) {
            return result.value
        }
        throw Error("Cannot access value for failed result")
    },
    getError<T>(result: ApiResult<T>): ApiError {
        if (this.isFailure(result)) {
            return result.error;
        }
        throw Error("Cannot access error for successfull result")
    },
    isSuccess<T>(result: ApiResult<T>): result is ApiSuccessResult<T> {
        return result.hasResponse && !result.error
    },
    isFailure(result: ApiResult<any>): result is ApiFailureResult {
        return result.hasResponse && Boolean(result.error)
    },
}

function getApiResult(error: Error): ApiError {
    console.log("getApiResult()", error)

    if (instanceOfHttpError(error)) {
        console.log("instanceOfHttpError is true")
        const report = error.body
        if (instanceOfProblemReport(report)) {
            console.log("instanceOfProblemReport is true")
            return {
                exception: error,
                problemDetails: report,
                message: getErrorMessage(report)
            }
        }
    }

    console.log("default api result")

    return {
        exception: error,
        message: error.message
    }
}

function getErrorMessage(details: ProblemDetails): string {
    if (details.code === "ErrorTimeConflict") {
        return "The resource is already booked please try again"
    }
    return details.title
}


function instanceOfHttpError(object: any): object is HttpError {
    return (typeof object === 'object') && 'statusText' in object && 'status' in object;
}

function instanceOfProblemReport(object: any): object is ProblemDetails {
    return (typeof object === 'object') && 'code' in object && 'detail' in object && 'status' in object && 'title' in object;
}




