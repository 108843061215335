import { combineReducers } from "redux"
import { getType } from "typesafe-actions"
import { LaunchAction } from "../../auth/applicationHostingClient"
import { Actions, actions } from "../types"

function startingLaunchAction(state: LaunchAction | null = null, action: Actions): LaunchAction | null {
    switch (action.type) {
        case getType(actions.registerStartingLaunchAction): {
            return action.payload
        }
        case getType(actions.nearbyTap): {
            // clear any launch action previously set
            return null
        }
    }

    return state
}

function bookItBaseUrl(state: string | null = null, action: Actions): string | null {
    switch (action.type) {
        case getType(actions.setBookItBaseUrl): {
            return action.payload
        }
    }

    return state
}


export default combineReducers({
    startingLaunchAction,
    bookItBaseUrl
})