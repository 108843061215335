import { Pivot, PivotItem, PivotLinkFormat, PivotLinkSize } from 'office-ui-fabric-react/lib/Pivot';
import * as React from 'react';
import { minutesTillMidnigt } from '../../utils/dateUtils';

export interface IDurationSelectorProps {
    onSelectionChange: (duration: number) => void;
    max: number
}

const durationItems = [
    { text: '15m', key: 15 },
    { text: '30m', key: 30 },
    { text: '1h', key: 60 },
    { text: '2h', key: 120 },
    { text: '4h', key: 240 },
];
 
const DurationSelector: React.FunctionComponent<IDurationSelectorProps> = (props) => {
    const endOfDayMins = minutesTillMidnigt()
    const all = [...durationItems, { text: 'All Day', key: endOfDayMins }]
    const items =  all.filter(d => d.key <= props.max)

    const handleClick = (item: PivotItem | undefined) => {
        if (item) {
            props.onSelectionChange(Number(item.props.itemKey));
        }
    }
 
    return (
        <div>
            <Pivot onLinkClick={handleClick} 
                    linkSize={PivotLinkSize.normal}
                    linkFormat={PivotLinkFormat.tabs}>
                {items.map((item) => {
                    return (
                        <PivotItem headerText={item.text} itemKey={item.key.toString()} key={item.key} />
                    );
                })}
            </Pivot>
        </div>
    );
};
 
export default DurationSelector;
 