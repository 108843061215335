import { combineReducers } from "redux"
import { getType } from "typesafe-actions"
import { NearbyTap } from "../../models"
import { Actions, actions } from "../types"

function tapInfo(state: NearbyTap | null = null, action: Actions): NearbyTap | null {
    switch (action.type) {
        case getType(actions.nearbyTap): {
            if (action.payload.state) {
                return action.payload
            }
        }
    }

    return state
}


export default combineReducers({
    tapInfo,
}) 