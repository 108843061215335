import { all, Channel, takeEvery as takeEveryUntyped } from 'async-saga';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { actions } from '..';
import { LaunchAction, listenLaunchAction } from '../../auth/applicationHostingClient';
import { NearbyTap, VideoFxRoomTap } from '../../models';
import { ApiResult } from '../reducers/ApiResult';
import { services } from '../services';
import { SagaContext, takeEvery, waitForState } from './async-saga';


async function hosted(ctx: SagaContext) {
    await waitForState(ctx, s => ApiResult.isSuccess(s.auth.user) || ApiResult.isFailure(s.auth.user))
    if (!services.hostingClient) { return }

    const action = await services.hostingClient.getLaunchAction()
    console.log("Found launch action on load", action)
    if (action) {
        await handleLaunchAction(ctx, action)
    }

    const channel = new Channel<LaunchAction>()
    listenLaunchAction(a => channel.put(a))
    services.hostingClient.appReady() // now we are listening cann signal the JS bridge
    console.log("Registered message handler")

    for (; ;) {
        handleLaunchAction(ctx, await channel.get())
    }
}

const switchCalendarContext = takeEvery(actions.switchCalendarContext, async function (ctx, calalendarContext) {
    try {
        console.log("switchCalendarContext fetchUpcomingMeetings", ctx.getState().router.location, calalendarContext)
        ctx.dispatch(actions.fetchUpcomingMeetings.request(calalendarContext))
        const pushState = { state: { ...(ctx.getState().router.location.state || {}), [calalendarContext.email]: true } }
        console.log("switchCalendarContext push", pushState)
        ctx.dispatch(push("/", pushState))
        const client = services.hostingClient
        if (client && calalendarContext.resourceType !== null) {
            client.showBack(true)
            client.setTitle("Room Schedule")
            client.setFABAction("StartBooking", [calalendarContext.email, calalendarContext.resourceType])
        }
    } catch (e) {
        console.error("Failed to switchCalendarContext", e)
    }
})

const restoreCalendarContext = takeEveryUntyped(LOCATION_CHANGE, async function (ctx: SagaContext) {
    try {
        const myself = ctx.getState().auth.user
        console.log("restoreCalendarContext", ctx.getState().router.location)

        if (ctx.getState().router.location.state !== undefined) return

        if (services.hostingClient === undefined) return

        if (ApiResult.isSuccess(myself)) {
            console.log(`Reverting calendar context to ${myself.value.emailAddress}`, ctx.getState().router.location)
            ctx.dispatch(actions.fetchUpcomingMeetings.request({ email: myself.value.emailAddress, name: `${myself.value.givenName} ${myself.value.familyName}`, resourceType: null }))
        }
        const client = services.hostingClient
        client!.showBack(false)
        client!.setTitle("My Schedule")
        client!.setFABAction("LocationCode", undefined)
    } catch (e) {
        console.error("Failed to restoreCalendarContext", e)
    }
})

const navigateAppTo = takeEvery(actions.navigateAppTo, async function (ctx, { app, path }) {
    try {
        const client = services.hostingClient
        client!.navigateTo(app, path)
    } catch (e) {
        console.error("Failed to navigateTo", e)
    }
})

const onLaunchAction = takeEvery(actions.launchAction, async function (ctx, { app, args }) {
    try {
        const client = services.hostingClient
        client!.launchAction(app, args)
    } catch (e) {
        console.error("Failed to send app action", e)
    }
})

async function handleLaunchAction(ctx: SagaContext, [type, json]: LaunchAction) {

    console.log("[handleLaunchAction] type:", type)

    switch (type.toLowerCase()) {
        case 'hotdesk':
        case 'locker':
        case 'carpark':
        case 'quickbook':
        case 'nearby':
            const data: NearbyTap = JSON.parse(json)
            data.type = type
            console.log("dispatching nearbyTap data:", data)
            ctx.dispatch(actions.nearbyTap(data))
            break
        case 'videofxroomtapped': {
            const data: VideoFxRoomTap = JSON.parse(json)
            ctx.dispatch(actions.videoFxRoomTap(data))
            break
        }
        case 'navigate':
            console.log("json: ", json)
            if (json === "/") {
                const client = services.hostingClient
                client!.showBack(false)
                client!.setTitle("My Schedule")
                client!.setFABAction("LocationCode", undefined)
            }
            break
    }
}

export default all(
    hosted,
    navigateAppTo,
    onLaunchAction,
    switchCalendarContext,
    restoreCalendarContext,
)
