import { TeamInfo, StartVideoFxMeetingModel } from '../models';
import { urlJoin } from '../utils/urlJoin';
import { AuthenticationProvider } from './AuthenticationProvider';
import { Context } from './Context';
import { HTTPClient } from './HTTPClient';
import { HTTPClientFactory } from './HTTPClientFactory';
import { RequestMethod } from './RequestMethod';
import { ClientError } from './VideoFxClient';
import { services } from '../store/services';

export class VideoFxRoomClient {
    private httpClient: HTTPClient
    private baseUrl: string;
    private authProvider: AuthenticationProvider

    public static async create(organisationId: string, roomId: string) {
        
        if (!services.videoFxClient) return undefined;

        const room = await services.videoFxClient.fetchRoom(organisationId, roomId);
        return new VideoFxRoomClient(room._embedded.connection.room_api.find(item => item.connection_type === "CloudTunnel")!.url, services.authProvider!);
    }

    private constructor(baseUrl: string, authProvider: AuthenticationProvider) {
        if (!baseUrl || baseUrl === "") {
            throw Error("Must set the baseUrl")
        }
        this.baseUrl = baseUrl;
        this.authProvider = authProvider;
        this.httpClient = HTTPClientFactory.createWithAuthenticationProvider(authProvider)
    }

    public startDesktopApplication(link: string, body: StartDesktopApplicationModel) {
        return this.postNoContent(this.getUrl(link), { 'Content-Type': 'application/json' }, body);
    }

    public addTeamToRoom(link: string, team: TeamInfo) {
        return this.postNoContent(this.getUrl(link), { 'Content-Type': 'application/json' }, team);
    }

    public async leaveMeeting(link: string) {
        const options: RequestInit = {
            method: RequestMethod.DELETE,
            headers: { 'Content-Type': 'application/json' },
        };
        await this.send(options, this.getUrl(link), (status: number) => status === 204)
    }

    public async startVfxMeeting(link: string, body: StartVideoFxMeetingModel) {
        if (body.conversation_type === 'StartTeamsAdhocAsUser') {
            body.bearer_token = await this.authProvider.getAccessToken()
        }
        return this.postNoContent(this.getUrl(link), { 'Content-Type': 'application/json' }, body);
    }

    private getUrl(path: string) {
        return urlJoin([this.baseUrl, path])
    }

    // private async get<TResponse>(request: RequestInfo): Promise<TResponse> {
    //     const options: RequestInit = {
    //         method: RequestMethod.GET
    //     };
    //     const context = await this.send(options, request, (status: number) => status < 400)
    //     return await context.response!.json() as TResponse
    // }

    private async post<TResponse>(request: RequestInfo, headers: HeadersInit, body: any): Promise<TResponse> {
        const options: RequestInit = {
            method: RequestMethod.POST,
            headers: headers,
            body: JSON.stringify(body)
        };
        const context = await this.send(options, request, (status: number) => status < 400)
        return await context.response!.json() as TResponse
    }

    private async postNoContent(request: RequestInfo, headers: HeadersInit = {}, body: any = {}) {
        const options: RequestInit = {
            method: RequestMethod.POST,
            headers: headers,
            body: JSON.stringify(body)
        };
        await this.send(options, request, (status: number) => status === 204)
    }

    private async send(options: RequestInit, request: RequestInfo, expectedStatus: (status: number) => boolean): Promise<Context> {
        let error: Error | undefined
        try {
            const context = await this.httpClient.sendRequest({ request, options })

            if (context.response) {
                if (expectedStatus(context.response.status)) {
                    return context
                }
                console.error(`${options.method} ${request} HTTP error: ${context.response.status} ${context.response.statusText}`)
                const clientError = new ClientError("HTTP Error", context.response.status)
                clientError.response = context.response
                error = clientError
            } else {
                error = new ClientError("Unknown error")
            }
        } catch (error) {
            console.error(`${options.method} ${request} failed`, error)
            throw error
        }
        throw error
    }
}

interface StartDesktopApplicationModel {
    sign_in_address: string,
    password: string | null,
}



