import moment from "moment"
import { MeetingData, ResourceMeeting, ResourceType } from "../models"

type ResourceOnlyMeeting = MeetingData & { resourceMeetings: Array<ResourceMeeting> }

export function isMeetingStarted(meeting: MeetingData, emailAddress: string): boolean {
  return hasMeetingException(meeting) && meeting.meetingExceptions[emailAddress].startTime > 0
}

export function isMeetingFinished(meeting: MeetingData, emailAddress: string) {
  return hasMeetingException(meeting) && meeting.meetingExceptions[emailAddress].endTime > 0
}

export function isResourceOnlyMeeting(meeting: MeetingData): meeting is  ResourceOnlyMeeting {
  return meeting.meetingStyle === "BookableResource" && meeting.resourceMeetings !== null && meeting.resourceMeetings.length > 0
}

export function hasMeetingException(meeting: MeetingData) {
  return meeting.meetingExceptions && Object.keys(meeting.meetingExceptions).length > 0
}

export function isOrganiser(meeting: MeetingData, emailAddress: string) {
  return isSameEmailAddress(meeting.organiser?.emailAddress, emailAddress)
}

export function isParticipant(meeting: MeetingData, emailAddress: string) {
  return isOrganiser(meeting, emailAddress) ||
    meeting.participants.some(p => isSameEmailAddress(p.emailAddress, emailAddress))
}

export function isThisRoomInvited(meeting: MeetingData, roomEmail: string) {
  return meeting.meetingRooms.filter(r => isSameEmailAddress(r.emailAddress, roomEmail)).length > 0
}

export function isMeetingToday(meeting: MeetingData) {
  return moment(meeting.startTime).date === moment().date || moment(meeting.endTime).date === moment().date
}

export function isResourceOnlyMeetingPending(meeting: ResourceOnlyMeeting): boolean {
  return !Boolean(meeting.resourceMeetings[0].id)
}

export function getResourceTypeDisplayName(type: ResourceType) {
  switch (type) {
    case 'MeetingRoom':
      return 'Room'
    case 'HotDesk':
      return 'Hot Desk'
    case 'Locker':
      return 'Locker'
    case 'ParkingSpace':
      return 'Carpark'
    default:
      return 'Resource'
  }
}

export function getResourceMeetingType(meeting: MeetingData): ResourceType | null {
  if (isResourceOnlyMeeting(meeting)) {
    //console.log(`getResourceMeetingType(${meeting.subject}): true`, meeting, meeting.resourceMeetings![0].resourceType)
      return meeting.resourceMeetings![0].resourceType
  }
  //console.log(`getResourceMeetingType(${meeting.subject}): null`, meeting)
  return null
}

export function getFormattedDuration(start: moment.Moment, end: moment.Moment) {
  const dur = moment.duration(end.diff(start))

  let str = []
  if (dur.days() > 0) {
    str.push(`${dur.days()}d`)
  }
  if (dur.hours() > 0) {
    str.push(`${dur.hours()}h`)
  }
  if (dur.minutes() > 0) {
    str.push(`${dur.minutes()}m`)
  }
  const res = str.join(" ")
  return res
}

function isSameEmailAddress(emailA?: string, emailB?: string): boolean {

  if (!emailA || !emailB) {
    return false;
  }

  return emailA.toLowerCase() === emailB.toLowerCase()

}

export function allDayToday(now: number): [number, number] {
  return [moment(now).startOf('day').valueOf(), moment(now).add(1, 'd').startOf('day').valueOf()]
}

