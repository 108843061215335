import { combineReducers } from "redux"
import { getType } from "typesafe-actions"
import { actions, Actions } from "../types"
import { ApiError } from "./ApiResult"


function apiError(state: ApiError | null = null, action: Actions): ApiError | null {
    switch (action.type) {
        case getType(actions.reportApiError):
            return action.payload
        case getType(actions.dismissApiError):
            return null
        default:
            return state
    }
}

export default combineReducers({
    apiError
})
