import { Fabric, FontIcon, mergeStyles, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack, Text } from 'office-ui-fabric-react';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';
import { actions } from '../../store';
import { createClients } from '../../store/actions';
import { ApiResult } from '../../store/reducers/ApiResult';
import { useSelector } from '../../store/utils';
import { Home } from '../Home';
import { LoggedInStatus } from '../LoggedInStatus';
import { VideoFxRoomTap } from '../VideoFxRoomTap';
import './App.css';
import { DefaultMessageBarStyle } from './MessageBarStyle';
import OidcCallback from './OidcCallback';
import SilentRenew from './OidcSilentRenew';

export const App: React.FC = () => {

  const dispatch = useDispatch()

  const { user } = useSelector(s => s.auth)
  const { location } = useSelector(s => s.router)
  const { isLoadingUser } = useSelector(s => s.oidc)
  const { oidc } = useSelector(s => s)
  useEffect(() => {
    if (!user.isRequested && !isLoadingUser && !location.pathname.startsWith("/oidc")) {
      dispatch(createClients.request())
    }
  }, [dispatch, isLoadingUser, location, oidc, user])

  return (
    <Fabric className='app-root'>
      <Switch>
        <Route exact path='/oidcSilentRenew'>
          <SilentRenew />
        </Route>
        <Route exact path='/oidcCallback'>
          <OidcCallback />
        </Route>
        <Route>
          {user.isLoading && <Spinner label="Signing in..." size={SpinnerSize.large} ariaLive="assertive" labelPosition="bottom" className='login-spinner' />}
          {ApiResult.isFailure(user) && <HostingError />}
          {ApiResult.isSuccess(user) && <AppBody />}
        </Route>
      </Switch>
    </Fabric>
  );
}

const AppBody: React.FC<{}> = () => {
  const apiError = useSelector(s => s.error.apiError)
  const dispatch = useDispatch()

  const handleDismiss = useCallback(() => dispatch(actions.dismissApiError()), [dispatch])

  return <div className='appBody'>
    {apiError && <MessageBar messageBarType={MessageBarType.error}
      isMultiline={true}
      dismissButtonAriaLabel="Close"
      onDismiss={() => handleDismiss()}
      styles={DefaultMessageBarStyle}
    >
      Error: {apiError.message ?? "Unknown"}
    </MessageBar>}
    <Switch>
      <Route exact path='/logged-in-status/:roomName' children={<LoggedInStatus />} />
      <Route exact path='/videofx-room-tap' children={<VideoFxRoomTap />} />
      <Route>
        <Home />
      </Route>
    </Switch>
  </div>
}

const HostingError = () => {

  const iconClass = mergeStyles({
    fontSize: 48,
    margin: '0 1rem',
    color: 'red'
  });

  return (<Stack horizontal padding={16} >
    <FontIcon iconName="Error" className={iconClass} />
    <Stack.Item align="center">
      <Text variant='mediumPlus'>There was a problem contacting the server. Please try again. If the problem persists please re-start the application.</Text>
    </Stack.Item>
  </Stack>
  )
}

