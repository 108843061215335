import moment from "moment";
import { CalendarContext, isFreeCard, isMeetingCard, MeetingCardType, MeetingData } from "../models";
import { isMeetingFinished, isMeetingStarted, isOrganiser, isParticipant, isResourceOnlyMeeting, isResourceOnlyMeetingPending } from "./meetingUtils";

const MINUTES_BEFORE_CAN_START = 15

export interface MeetingOperations {
    canStart: boolean
    canLeave: boolean
    canExtend: boolean
    canCancel: boolean
    canShowInfo: boolean
    showIsStarted: boolean
    startMeetingId?: string
    leaveMeetingId?: string
    canEdit: boolean
    canAddResources: boolean
}

const notPermitted: MeetingOperations = {
    canStart: false,
    canLeave: false,
    canExtend: false,
    canCancel: false,
    canShowInfo: false,
    showIsStarted: false,
    canEdit: false,
    canAddResources: false,
}

export function meetingOperations(now: number, myself: string, meeting: MeetingData, context: CalendarContext | undefined, index: number, allMeetings: MeetingCardType[]): MeetingOperations {

    if (context === undefined) {
        return notPermitted
    }

    const calendarContext = context

    // are we performing these operations on a Room Schedule (not our Schedule)
    const resourceCalendar = context.resourceType !== null

    let meetingOps: MeetingOperations
    if (resourceCalendar) {
        meetingOps = {
            canStart: roomScheduleCanStart(now),
            canLeave: roomScheduleCanLeave(),
            canExtend: roomScheduleCanExtend(),
            canCancel: roomScheduleCanCancel(now),
            showIsStarted: roomScheduleShowIsStarted(),
            canShowInfo: false,
            startMeetingId: meeting.id,
            leaveMeetingId: meeting.id,
            canEdit: canEdit(),
            canAddResources: canAddResources(),
        }
        //console.log(`Room Schedule meeting operations (${moment(meeting.startTime).format('MMM DD HH:mm')}): `, meetingOps)
    } else {
        meetingOps = {
            canStart: myScheduleCanStart(now),
            canLeave: myScheduleCanLeave(),
            canExtend: myScheduleCanExtend(),
            canCancel: myScheduleCanCancel(now),
            showIsStarted: myScheduleShowIsStarted(),
            canShowInfo: myScheduleCanShowInfo(),
            startMeetingId: myScheduleResourceMeetingId(),
            leaveMeetingId: myScheduleResourceMeetingId(),
            canEdit: canEdit(),
            canAddResources: canAddResources(),
        }
        //console.log(`My Schedule meeting operations (${moment(meeting.startTime).format('MMM DD HH:mm')}): `, meetingOps)
    }

    return meetingOps

    // #region Base rules appplied to all operations regardless of context
    function canStartBase(now: number, emailAddress: string) {
        return moment(meeting.startTime).diff(moment(now), 'm', true) < MINUTES_BEFORE_CAN_START
            && moment(meeting.endTime).isAfter(now)
            && !isMeetingStarted(meeting, emailAddress)
            && !isMeetingFinished(meeting, emailAddress)
    }

    function canLeaveBase(emailAddress: string) {
        return isMeetingStarted(meeting, emailAddress) && !isMeetingFinished(meeting, emailAddress)
    }

    function canExtendBase() {
        return !meeting.isAllDayEvent

    }

    function canCancelBase(now: number) {
        return meeting.endTime > now
            && isOrganiser(meeting, myself)
    }

    function showIsStartedBase(emailAddress: string) {
        return isMeetingStarted(meeting, emailAddress) && !isMeetingFinished(meeting, emailAddress)
    }

    function canEdit() {
        return isOrganiser(meeting, myself)
    }

    function canAddResources() {
        return moment(meeting.endTime).isAfter(now)
            && isParticipant(meeting, myself)
    }

    // #endregion
    function roomScheduleCanStart(now: number): boolean {
        return (isOrganiser(meeting, myself) || isParticipant(meeting, myself))
            && canStartBase(now, calendarContext.email)
    }

    function roomScheduleCanLeave(): boolean {
        return (isOrganiser(meeting, myself) || isParticipant(meeting, myself))
            && canLeaveBase(calendarContext.email)
    }

    function roomScheduleCanExtend(): boolean {
        let freeNext = true
        if (index < allMeetings.length - 1) { // not last
            freeNext = false
                const next = allMeetings[index + 1]
                if (isFreeCard(next)) { 
                    freeNext = true
                }
                if (isMeetingCard(next)) {
                    freeNext = next.startTime - meeting.endTime > 0
                }

        }

        return (isOrganiser(meeting, myself) || isParticipant(meeting, myself)) 
            && canExtendBase() && freeNext
    }

    function roomScheduleCanCancel(now: number): boolean {
        // console.log(`roomScheduleCanCancel: isOrganiser=${isOrganiser(meeting, myself)}, canCancelBase=${canCancelBase(now)}`)
        return canCancelBase(now)
    }

    function roomScheduleShowIsStarted(): boolean {
        return showIsStartedBase(calendarContext.email)
    }
    // #region Rules appplied when on Room Schedule

    // #endregion

    // #region Rules appplied when on My Schedule
    function myScheduleCanStart(now: number): boolean {
        return isResourceOnlyMeeting(meeting)
            && isOrganiser(meeting, myself)
            && !isResourceOnlyMeetingPending(meeting)
            && canStartBase(now, meeting.resourceMeetings[0].emailAddress)
    }

    function myScheduleCanLeave(): boolean {
        // console.log(`isResourceOnlyMeeting=${isResourceOnlyMeeting(meeting)}, isOrganiser=${isOrganiser(meeting, myself)}, isResourceOnlyMeetingPending=${isResourceOnlyMeeting(meeting) && isResourceOnlyMeetingPending(meeting)}`)
        return isResourceOnlyMeeting(meeting)
            && isOrganiser(meeting, myself)
            && !isResourceOnlyMeetingPending(meeting)
            && canLeaveBase(meeting.resourceMeetings[0].emailAddress)
    }

    function myScheduleCanExtend(): boolean {
        return isResourceOnlyMeeting(meeting)
            && isOrganiser(meeting, myself)
            && !isResourceOnlyMeetingPending(meeting)
            && canExtendBase()
    }

    function myScheduleCanCancel(now: number): boolean {
        return canCancelBase(now)
    }

    function myScheduleShowIsStarted(): boolean {
        return isResourceOnlyMeeting(meeting)
            && showIsStartedBase(meeting.resourceMeetings[0].emailAddress)
    }

    function myScheduleCanShowInfo(): boolean {
        return isResourceOnlyMeeting(meeting)
            && Boolean(meeting.resourceMeetings[0].emailText)
    }

    function myScheduleResourceMeetingId() {
        if (meeting.resourceMeetings && meeting.resourceMeetings.length > 0) return meeting.resourceMeetings[0].id
        return undefined
    }
    // #endregion



}