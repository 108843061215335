import { NeutralColors } from '@uifabric/fluent-theme';
import { Card, ICardStyles } from '@uifabric/react-cards';
import { FontWeights, ITextStyles, Text } from 'office-ui-fabric-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { services } from '../../store/services';
import './LoggedInStatus.css';


interface LoggedInStatusProps {

}

const cardStyles: ICardStyles = {
  root: {
    padding: 16,
    minWidth: 0,
    maxWidth: 'auto',
    background: NeutralColors.white,
    textAlign: 'center'
  }
}

const titleTextStyles: ITextStyles = {
  root: {
    fontSize: '0.8rem',
    fontWeight: FontWeights.semibold
  }
}

const roomTextStyles: ITextStyles = {
  root: {
    color: "rgb(0, 120, 212)"

  }
}

// const iconStyles: IIconStyles = {
//   root: {
//     color: '#0078D4',
//     fontSize: 16,
//     fontWeight: FontWeights.regular,
//     marginTop: 8
//   }
// };

// const footerCardSectionStyles: ICardSectionStyles = {
//   root: {
//     borderTop: '1px solid #F3F2F1'
//   }
// };

// const footerCardSectionTokens: ICardSectionTokens = { padding: 0 };

export function LoggedInStatus(props: LoggedInStatusProps) {

  const { roomName } = useParams<{ roomName: string }>()

  useEffect(() => {
    const client = services.hostingClient
    if (client) {
      client.showBack(true)
      client.setTitle(roomName)
    }
  }, [roomName])

  // const handleSignOut = useCallback(() => {
  // }, [])

  return <main className='app-home'>
    <div className='app-card-list'>
      <Card aria-label="Logged In Reminder" styles={cardStyles}>
        <Card.Item align="start">
          <Text styles={titleTextStyles}>You are now logged in to the tablet for </Text><Text styles={roomTextStyles}>{roomName}</Text>
        </Card.Item>

        <Card.Item align="start">
          <Text styles={titleTextStyles}>Please remember to log out when finished</Text>
        </Card.Item>
        {/* <Card.Section horizontal styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
          <IconButton iconProps={{ iconName: "SignOut" }} styles={iconStyles} onClick={() => handleSignOut()} />
        </Card.Section> */}
      </Card>
    </div>
  </main>

}


