import moment from "moment";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MeetingData } from "../../models";
import { actions } from "../../store";
import { ApiResult } from "../../store/reducers/ApiResult";
import { useSelector } from "../../store/utils";
import DurationSelector from "./DurationSelector";


interface AvailableDurationSelectorProps {
    onDurationChange: (duration: number) => void,
    onUnavailable: () => void,
    currentMeeting: MeetingData,
    resourceEmailAddress: string
}

export const AvailableDurationSelector: React.FC<AvailableDurationSelectorProps> = ({onDurationChange, onUnavailable, currentMeeting, resourceEmailAddress}) => {
    const dispatch = useDispatch()
    const allUpcomingMeetingsForResource = useSelector(s => s.meetings.upcomingMeetingsForResource)
    const [maxDuration, setMaxDuration] = useState(0)
    const [isBusy, setIsBusy] = useState(true)

    useEffect(() => {
        dispatch(actions.fetchUpcomingMeetingsForResource.request(resourceEmailAddress))
    }, [currentMeeting.meetingRooms, dispatch, resourceEmailAddress])

    useEffect(() => {
        if (ApiResult.isSuccess(allUpcomingMeetingsForResource)) {
            const next: MeetingData[] = allUpcomingMeetingsForResource.value.filter(m => m.startTime >= currentMeeting.endTime)
            if (next === null || next.length === 0) {
                setMaxDuration(60)
            } else {
                const availMinutes = moment.duration(moment(next[0].startTime).diff(moment(currentMeeting.endTime)))
                if (availMinutes.asMinutes() < 15) {
                    onUnavailable()
                } else {
                    setMaxDuration(availMinutes.asMinutes())                
                }
            } 
            setIsBusy(false)
        } 
    }, [allUpcomingMeetingsForResource, currentMeeting.endTime, onUnavailable])
    
    return isBusy ? <Spinner size={SpinnerSize.medium} /> : <DurationSelector onSelectionChange={onDurationChange} max={maxDuration} />

}