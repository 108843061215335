import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { VideoFxRoomClient } from '../../api/VideoFxRoomClient';
import { NfcTagType, ResourceType, WhoAmI } from '../../models';
import { actions, Actions } from '../types';
import { createApiReducer } from './createApiReducer';

function videoFxRoomClients(state: Record<string, VideoFxRoomClient> = {}, action: Actions): Record<string, VideoFxRoomClient> {
    switch (action.type) {
        case getType(actions.createRoomApiClient.request): {
            // maybe we can do something here in the future.
            break;
        }
        case getType(actions.createRoomApiClient.success): {
            const key = action.payload.organisationId + '_' + action.payload.roomId
            return { ...state, [key]: action.payload.client }
        }
    }

    return state
}

interface AllowedState {
    nfcTags: Array<NfcTagType>
    resources: Array<ResourceType>
}
function allowed(state: AllowedState = {nfcTags:[], resources: []}, action: Actions): AllowedState {
    switch (action.type) {
        case getType(actions.createClients.success): {
            return { ...state, nfcTags: allowedNfcTagsFromRoles(action.payload.roles), resources: allowedResourcesFromRoles(action.payload.roles) }
        }
    }

    return state
}

function whoAmI(state: WhoAmI|null = null, action: Actions): WhoAmI|null {
    switch (action.type) {
        case getType(actions.whoAmI):
            return action.payload
    }
    return state
}

function allowedNfcTagsFromRoles(roles: string[]): Array<NfcTagType> {
    const res: Array<NfcTagType> = []
    if (roles.some(r => r === 'BookItDeviceNfcTag')) res.push('BookItDevice')
    if (roles.some(r => r === 'HotDeskNfcTag')) res.push('HotDesk')
    if (roles.some(r => r === 'QuickBookNfcTag')) res.push('QuickBook')
    if (roles.some(r => r === 'NearbyNfcTag')) res.push('Nearby')
    if (roles.some(r => r === 'VideoFxRoomNfcTag')) res.push('VideoFxRoom')
    return res
}

function allowedResourcesFromRoles(roles: string[]): Array<ResourceType> {
    const res: Array<ResourceType> = []
    if (roles.some(r => r === 'MeetingRoomBook')) res.push('MeetingRoom')
    if (roles.some(r => r === 'HotDeskBook')) res.push('HotDesk')
    if (roles.some(r => r === 'CarparkBook')) res.push('ParkingSpace')
    if (roles.some(r => r === 'LockerBook')) res.push('Locker')
    return res
}

export default combineReducers({
    user: createApiReducer(actions.createClients),
    videoFxRoomClients,
    allowed,
    whoAmI
})


