import { AuthenticationHandler } from './AuthenticationHandler';
import { AuthenticationProvider } from './AuthenticationProvider';
import { HTTPClient } from './HTTPClient';
import { HTTPMessageHandler } from './HTTPMessageHandler';
import { Middleware } from './Middleware';
export class HTTPClientFactory {
    /**
     * NOTE: These are the things that we need to remember while doing modifications in the below default pipeline.
     * 		* HTTPMessageHander should be the last one in the middleware pipeline, because this makes the actual network call of the request
     * 		* The best place for AuthenticationHandler is in the starting of the pipeline, because every other handler might have to work for multiple times for a request but the auth token for
     * 		  them will remain same. For example, Retry and Redirect handlers might be working multiple times for a request based on the response but their auth token would remain same.
     */
    public static createWithAuthenticationProvider(authProvider: AuthenticationProvider): HTTPClient {
        const authenticationHandler = new AuthenticationHandler(authProvider);
        const httpMessageHandler = new HTTPMessageHandler();
        authenticationHandler.setNext(httpMessageHandler);
        return HTTPClientFactory.createWithMiddleware(authenticationHandler);
    }
    public static createWithMiddleware(middleware: Middleware): HTTPClient {
        return new HTTPClient(middleware);
    }
}
