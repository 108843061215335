import { all } from "async-saga";
import { push } from "connected-react-router";
import { actions } from "..";
import { AuthenticationProvider } from "../../api/AuthenticationProvider";
import { createBookItClient } from "../../api/BookItClient";
import { VideoFxClient } from "../../api/VideoFxClient";
import { VideoFxRoomClient } from "../../api/VideoFxRoomClient";
import { ApplicationHostingAuthenticationProvider } from "../../auth/ApplicationHostingAuthenticationProvider";
import { createAndroidHostingClient, createiOSHostingClient, createOidcHostingClient } from "../../auth/applicationHostingClient";
import { OidcClientAuthenticationProvider } from "../../auth/OidcClientAuthenticationProvider";
import Config from "../../auth/oidcConfig";
import userManager from "../../auth/userManager";
import { services } from "../services";
import { takeEvery } from "./async-saga";

const createClientsSaga = takeEvery(actions.createClients.request, async function (ctx) {
    try {
        const client = createAndroidHostingClient() ?? createiOSHostingClient()
        if (client !== undefined) {
            console.log(`Using ${client.platform} Hosting Client - client:`, client)
            services.hostingClient = client
            const launchAction = await services.hostingClient.getLaunchAction()
            ctx.dispatch(actions.registerStartingLaunchAction(launchAction))
            const bookItBaseUrl = await services.hostingClient.bookItApiBaseUrl()
            ctx.dispatch(actions.setBookItBaseUrl(bookItBaseUrl))

            services.authProvider = new ApplicationHostingAuthenticationProvider(client);
            services.videoFxClient = VideoFxClient.createWithAuthProvider(await client.videoFxApiBaseUrl(), services.authProvider)
            const result = await internalCreateBookItClient(await client.bookItApiBaseUrl(), services.authProvider)
            if (!result) {
                ctx.dispatch(actions.createClients.failure(new Error('Book-It bearer login failed.')))
            } else {
                services.bookItClient = result.client
                ctx.dispatch(actions.whoAmI(result.whoAmI))
                ctx.dispatch(actions.createClients.success(await client.user()))
            }
        } else {
            console.error("Falling back to OIDC Hosting")
            // fallback to oidc-client when not hosted in android app
            const user = await userManager.getUser()
            if (!user || user.expired) {
                // if no user found, or token has expired, auto redirect to identity server signin page
                // pass the current path to redirect to the correct page after successfull login
                userManager.signinRedirect({ data: { path: ctx.getState().router.location.pathname } });
            } else {
                const oidc = createOidcHostingClient(user.profile.sub ?? "unknown",
                    user.profile.family_name ?? "unknown",
                    user.profile.given_name ?? "unknown",
                    Config.serverApiRoot, Config.bookitApiRoot,
                    url => ctx.dispatch(push(url))
                )
                const authProvider = new OidcClientAuthenticationProvider(userManager)
                services.hostingClient = oidc
                services.videoFxClient = VideoFxClient.createWithAuthProvider(await oidc.videoFxApiBaseUrl(), authProvider)
                const result = await internalCreateBookItClient(await oidc.bookItApiBaseUrl(), authProvider)
                if (!result) {
                    ctx.dispatch(actions.createClients.failure(new Error('Book-It bearer login failed.')))
                } else {
                    services.bookItClient = result.client
                    ctx.dispatch(actions.whoAmI(result.whoAmI))
                    ctx.dispatch(actions.createClients.success(await oidc.user()))
                }
            }
        }
    } catch (e) {
        ctx.dispatch(actions.createClients.failure(e))
    }
})

async function internalCreateBookItClient(baseUrl: string, authProvider: AuthenticationProvider) {
    const bookItClient = await createBookItClient(baseUrl)
    let whoAmI =  await bookItClient.whoAmI()

    if (!whoAmI) {
        console.info("whoAmI failed trying bearerLogin")
        whoAmI = await bookItClient.bearerLogin(await authProvider.getAccessToken())
        if (!whoAmI) {
            console.info("bearerLogin failed")
            return false
        }
    }

    bookItClient.setMyself(whoAmI.myself)
    return {client: bookItClient, whoAmI}
}

const createVideoFxRoomClientSaga = takeEvery(actions.createRoomApiClient.request, async function(ctx, orgRoom) {
    try {
        const client = await VideoFxRoomClient.create(orgRoom.organisationId, orgRoom.roomId);
        if ( client ) {
            ctx.dispatch(actions.createRoomApiClient.success(orgRoom.organisationId, orgRoom.roomId, client));
        } else {
            ctx.dispatch(actions.createRoomApiClient.failure(new Error("Unable to create room api client")))
        }
    } catch (e) {
        ctx.dispatch(actions.createRoomApiClient.failure(e))
    }
})

export default all(
    createClientsSaga,
    createVideoFxRoomClientSaga,
)
