
interface ConfigData {
    serverApiRoot: string
}

export default class Config {

    public static get serverApiRoot(): string {
        return Config.instance.serverApiRoot ? Config.instance.serverApiRoot
        // : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? "https://localhost:5000" : "https://api.ipfx.app"
        : process.env.NODE_ENV && process.env.NODE_ENV === "development" ? "https://api.ipfx.dev" : "https://api.ipfx.app"
    }

    public static get bookitApiRoot(): string {
        // return process.env.NODE_ENV === "development" ? "https://localhost:44355" : "https://bookit.ipfx.app"
        return process.env.NODE_ENV === "development" ? "https://bookit.ipfx.dev" : "https://bookit.ipfx.app"
    }

    private static get instance(): ConfigData {
        return ((window as any).videoFxConfig) as ConfigData
    }
}
