import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react"


type RefreshCallback = () => void
type RefreshContext = {
  register: (cb: RefreshCallback) => void
  unregister: (cb: RefreshCallback) => void
}
const RefreshContext = React.createContext<RefreshContext | null>(null)

export function RefreshContextProvider({ children }: React.PropsWithChildren<{}>) {
  const components = useRef<RefreshCallback[]>([])

  useEffect(() => {
    (window as any).isRefreshContentEnabled = true;
    (window as any).refreshContent = function() {
      if (components.current.length > 0) {
        components.current.forEach(x => x())
      } else {
        window.location.reload()
      }
    };
  }, [])

  const register = useCallback((cb: RefreshCallback) => {
    components.current.push(cb)
  }, [])

  const unregister = useCallback((cb: RefreshCallback) => {
    const index = components.current.indexOf(cb)
    if (index !== -1) {
      components.current.splice(index, 1)
    }
  }, [])

  const context = useMemo<RefreshContext>(() => ({
    register,
    unregister,
  }), [register, unregister])

  return <RefreshContext.Provider value={context}>
    {children}
  </RefreshContext.Provider>
}

export function useRefresh(cb: RefreshCallback) {
  const lastCb = useRef(cb)
  useEffect(() => {
    lastCb.current = cb
  }, [cb])
  const ctx = useContext(RefreshContext)
  if (ctx === null) {
    throw new Error('Where is my provider?!!!. Did you forget to use <RefreshContextProvider> in your app main')
  }
  useEffect(() => {
    ctx.register(callback)
    return () => { ctx.unregister(callback) }
    function callback() {
      lastCb.current()
    }
  }, [ctx])
}