import { all } from "async-saga";
import { ActionCreatorBuilder, PayloadActionCreator } from "typesafe-actions";
import { BookItClient } from "../../api/BookItClient";
import { services } from "../services";
import { actions } from "../types";
import { delay } from "../utils";
import { takeEvery } from "./async-saga";

const fetchUpcomingMeetings = processBookItApi(actions.fetchUpcomingMeetings, 
                async (client, parms) => ({context: parms, data: await client.getUpcomingMeetings(parms.email)}))
const fetchUpcomingMeetingsForResource = processBookItApi(actions.fetchUpcomingMeetingsForResource, (client, email) => client.getUpcomingMeetingsForResource(email))
const cancelMeeting = processBookItApi(actions.cancelMeeting, (client, id) => client.cancelMeeting(id), act => act)
const leaveBookItMeeting = processBookItApi(actions.leaveBookItMeeting, (client, id) => client.leaveMeeting(id), act => act)
const startMeeting = processBookItApi(actions.startMeeting, (client, id) => client.startMeeting(id), act => act)
const extendMeeting = processBookItApi(actions.extendMeeting, (client, parms) => client.extendMeeting(parms.id, parms.durationMinutes), parms => parms.id)

const refreshUpcomingMeetings = takeEvery(actions.refreshUpcomingMeetings, async function (ctx, background) {
    const upcoming = ctx.getState().meetings.upcomingMeetings
    if (upcoming) {
        ctx.dispatch(actions.fetchUpcomingMeetings.request(upcoming.context))
    }
})

export type ApiAction<TReq, TRes> = {
    request: PayloadActionCreator<string, TReq>
    success: ActionCreatorBuilder<string, TRes>
    failure: PayloadActionCreator<string, Error>
}


function processBookItApi<TP, TS>(action: ApiAction<TP, TS>, onRequest: (bookIt: BookItClient, payload: TP) => Promise<TS>,
            getMeetingId?: (payload: TP) => string) {
    return takeEvery(action.request as any, async function (ctx, payload: any) {
        const id = getMeetingId?.(payload)
        try {
            if (!services.bookItClient) {
                console.error("No BookItClient defined")
                return
            }

            if (id) {
                ctx.dispatch(actions.startApiCallForMeeting(id))        
            }
            console.log("action: ", action)
            console.log("About to dispatch api call with payload: ", payload)
            ctx.dispatch(action.success( await onRequest(services.bookItClient, payload)))

            if (id) {
                const upcomingMeetings = ctx.getState().meetings.upcomingMeetings
                if (upcomingMeetings) { // referesh if we have a context
                    await delay(2000)
                    ctx.dispatch(actions.fetchUpcomingMeetings.request(upcomingMeetings.context))  // refresh
                }
            }
        } catch (e) {
            ctx.dispatch(action.failure(e))
            ctx.dispatch(actions.reportApiError(e))
        } finally {
            if (id) {
                ctx.dispatch(actions.finishApiCallForMeeting(id))        
            }

        }
    })
}


export default all(
    fetchUpcomingMeetings,
    refreshUpcomingMeetings,
    fetchUpcomingMeetingsForResource,
    cancelMeeting,
    extendMeeting,
    leaveBookItMeeting,
    startMeeting
)