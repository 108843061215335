import { InferableComponentEnhancerWithProps } from 'react-redux';
import { ActionType, StateType } from "typesafe-actions";
import { LocationState } from '.';
import * as actions from './actions';
import { createRootReducer } from './reducers';


export { actions };
export type Actions = ActionType<typeof actions>;
export type RootAction =
  | Actions;


const wrapper = () => createRootReducer<LocationState>(null as any)

export type RootState = StateType<ReturnType<typeof wrapper>>;

export type ReduxPropsType<T extends InferableComponentEnhancerWithProps<any, any>> = T extends InferableComponentEnhancerWithProps<infer P, any> ? P : any;
